import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
  font-size: 0.875rem;
  .title {
    display: flex;
    align-items: center;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  height: 1.3rem;
  background-color: #f3f5f7;
  border-radius: 3px;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 2.5rem;
  height: 1.5rem;
  border-radius: 3px;
  text-align: center;
  align-items: center;
  &.active {
    background-color: #5f9137;
    color: #ffffff;
  }
  &.isLocal {
    background-color: #afafaf;
    color: #ffffff;
  }
  &.inactive {
    background-color: #f3f5f7;
    color: #afafaf;
  }
`;

const ContorllerEtc = ({
  title,
  houseID,
  agID,
  akID,
  onoffID,
  oppstate,
  isLocal,
  handleClick,
}) => {
  const { t } = useTranslation("dashboard", {
    keyPrefix: "manualController",
  });

  const [status, setStatus] = useState("off");

  useEffect(() => {
    // 초기 세팅 : 0이면 Off 1이면 On
    if (oppstate > 0) {
      setStatus("on");
    } else {
      setStatus("off");
    }
  }, [oppstate]);
  return (
    <Container>
      <div className="title">{t(`controllers.${title}`)}</div>
      <ButtonWrapper>
        <Button
          className={
            status === "on"
              ? isLocal === "Y"
                ? "isLocal"
                : "active"
              : "inactive"
          }
          onClick={
            isLocal === "N"
              ? () => {
                  // oppstate = 1 가동
                  setStatus("on");
                  handleClick(title, houseID, agID, akID, onoffID, 1);
                }
              : null
          }
        >
          {t("buttons.on")}
        </Button>
        <Button
          className={
            status === "off"
              ? isLocal === "Y"
                ? "isLocal"
                : "active"
              : "inactive"
          }
          onClick={
            isLocal === "N"
              ? () => {
                  // oppstate = 0 정지
                  setStatus("off");
                  handleClick(title, houseID, agID, akID, onoffID, 0);
                }
              : null
          }
        >
          {t("buttons.off")}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default ContorllerEtc;
