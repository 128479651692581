import React, { useEffect, useState } from "react";
import { getHouseSensorNode } from "../../api/SocketApi";
import useStore from "../../store/store";
// Switch문 Case로 사용
const CODE = {
  1: "온도",
  2: "습도",
  3: "이슬점",
  4: "VPD",
  5: "CO2",
  6: "HD",
  7: "토양 온도",
  8: "토양 습도",
  9: "일사량",
  10: "EC",
  11: "pH",
};
// HD : 수분부족분(기타)
const AssembleSensorData = () => {
  const { farm_id } = useStore();
  const [standard, setStandard] = useState([]);
  const [structure, setStructure] = useState([]);
  // 틀 만들기
  const makeJson = (sensor) => {
    let data = [];
    // list로 와서 for문 추가 됨
    sensor.forEach((item) => {
      const entry = {
        title: item.sm,
        value: -1,
        key: item.key,
        maximum: item.ch,
        minimum: item.cl,
        unit: item.un,
      };
      if (data.length === 0) {
        data.push(entry);
      } else if (
        //같은 게 있으면 안 됨. 같은게 없을 때 추가
        data.every((val) => val.key !== item.key)
      ) {
        data.push(entry);
      }
    });
    return data;
  };

  // 기준 가져오기
  useEffect(() => {
    if (farm_id) {
      getHouseSensorNode(farm_id)
        .then((res) => {
          setStandard(res);
        })
        .catch((error) =>
          console.error("Error getting house sensor nodes:", error)
        );
    }
  }, [farm_id]);

  useEffect(() => {
    if (standard.length !== 0) {
      const newStructure = standard.map((item) => ({
        snnam: item.snnam,
        data: makeJson(item.sensor),
      }));
      setStructure(newStructure);
    }
  }, [standard, farm_id]);

  return structure;
};

export default AssembleSensorData;
