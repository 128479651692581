export default function formatDate(milliseconds, key) {
  let newDate = new Date(milliseconds);
  const year = newDate.getFullYear();

  const month =
    +newDate.getMonth() + 1 < 10
      ? "0" + (+newDate.getMonth() + 1)
      : +newDate.getMonth() + 1;
  const date =
    newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
  const hour =
    newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
  const minute =
    newDate.getMinutes() < 10
      ? "0" + newDate.getMinutes()
      : newDate.getMinutes();
  const second =
    newDate.getMinutes() < 10
      ? "0" + newDate.getMinutes()
      : newDate.getMinutes();

  switch (key) {
    case "YYYY-MM-DD":
      return `${year}-${month}-${date}`;
    case "YYYY/MM/DD":
      return `${year}/${month}/${date}`;
    case "MM/DD":
      return `${month}/${date}`;
    case "MM.DD":
      return `${month}.${date}`;
    case "HH:MM:SS":
      return `${hour}:${minute}:${second}`;
    case "HH:MM":
      return `${hour}:${minute}`;
    case "HH":
      return `${hour}`;
    case "MM:SS":
      return `${minute}:${second}`;
    case "YYYY-MM-DD HH:MM":
      return `${year}-${month}-${date} ${hour}:${minute}`;
    case "YYYY-MM-DD HH:MM:SS":
      return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
    default:
      return milliseconds;
  }
}
