import  * as utils from './utils.js';
import  * as sensor from './sensor.js';

	export function refreshData(sCnt, rev_data, item_sensorNode) {
	
	
		

		//======================================		
		//센서노드에 붙어있는 센서의 갯수 첫번재 parameter로 들어온 값 sCnt
		item_sensorNode.sensorCount = sCnt;
		//복수개의 센서
		item_sensorNode.sensors = [];
		//======================================
		let pos = 0;
		for (let sidx=0; sidx<sCnt; sidx++) {
	    	let sid = rev_data[pos++]; 
	    	
	    	let ff = rev_data[pos++];
			let ss = rev_data[pos++];
	    	let buf_value = [ff, ss];
	    	let value = (utils.byte2Int(buf_value)) / 10.0;
	    	//let value = 10;

    		//======================================
    		//센서 생성
    		let item_sensor = {};
    		item_sensor.sensorID = sid;
    		item_sensorNode.sensors.push(item_sensor);	
    		//======================================
			//item_sensor -> last parameter
			//======================================
    		sensor.refreshData(value,  item_sensor);
	    	
	    }

	};

	
	