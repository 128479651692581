import React from "react";
import styled, { keyframes } from "styled-components";

// Skeleton loading animations
const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 14rem;
  background-color: #5f913713;
  border-radius: 1.6rem;
  padding: 1.25rem;
  position: relative;
  overflow: hidden;
  background: #5f913713;
  background-image: linear-gradient(
    to right,
    #5f913713 0%,
    #dddddd 20%,
    #5f913713 40%,
    #5f913713 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 312px;
  animation: ${shimmer} 1.2s infinite linear;
`;

const SkeletonHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  height: 2rem;
  width: 100%;
  margin-bottom: 1rem;
`;

const SkeletonTitle = styled.div`
  width: 50%;
  height: 1.5rem;
  background: #5f913713;
  border-radius: 0.25rem;
`;

const SkeletonGuideItemWrapper = styled.div`
  display: flex;
`;

const SkeletonGuideItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: #969696;
  margin-left: 0.5rem;
  height: 1rem;
  width: 3rem;
  background: #5f913713;
  border-radius: 0.25rem;
`;

const SkeletonSensorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .sensor {
    margin: 0.5rem 0;
    height: 1rem;
    background: #5f913713;
    border-radius: 0.25rem;
  }
`;

const SkeletonSensorItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 1rem;
`;

const SkeletonSensorItem = styled.div`
  height: 3rem;
  background: #5f913713;
  border-radius: 0.5rem;
`;

const SensorSkeleton = () => {
  return (
    <SkeletonWrapper>
      <SkeletonHeader>
        <SkeletonTitle />
        <SkeletonGuideItemWrapper>
          <SkeletonGuideItem />
          <SkeletonGuideItem />
        </SkeletonGuideItemWrapper>
      </SkeletonHeader>
      <SkeletonSensorWrapper>
        <div className="sensor" />
        <SkeletonSensorItemWrapper>
          <SkeletonSensorItem />
          <SkeletonSensorItem />
          <SkeletonSensorItem />
        </SkeletonSensorItemWrapper>
        <SkeletonSensorItemWrapper>
          <SkeletonSensorItem />
          <SkeletonSensorItem />
          <SkeletonSensorItem />
        </SkeletonSensorItemWrapper>
      </SkeletonSensorWrapper>
    </SkeletonWrapper>
  );
};

export default SensorSkeleton;
