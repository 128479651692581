import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Chart from "../../components/Chart.js";
import Title from "../../components/layout/Title.js";
import formatDate from "../../utils/formatDate.js";
import useStore from "../../store/store.js";
import {
  getGreenhouseData,
  getGreenhouseDataRealTime,
} from "../../api/GreenhouseApi.js";
import useInterval from "../../hooks/useInterval.js";
import getTimeFromDate from "../../utils/getTimeFromDate.js";
import Menu_Data from "../../assets/img/Menu_Data.svg";

const Container = styled.div`
  background-color: #ffffff;
  margin: 3.9rem 1rem 0 1rem;
  padding: 1rem 1rem 0 1rem;
`;
const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
const Button = styled.div`
  display: flex;
  min-width: 2rem;
  justify-content: center;
  align-items: center;
  background-color: #f7f9f5;
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
  &.active {
    background-color: #5f9137;
    color: #ffffff;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 0 1rem 0;
  font-size: 0.75rem;
`;
const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Circle = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 10px;
  background-color: ${(props) => (props.bc ? props.bc : "#5F9137")};
`;
const Legend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  color: #969696;
`;
const TimeButtonContainer = styled.div`
  display: flex;
`;
const TimeButtonWrapper = styled.div`
  display: flex;
  background-color: #f1f5ed;
  border-radius: 1rem;
  cursor: pointer;
`;
const TimeButton = styled.div`
  display: flex;
  justify-content: center;
  width: 2.5rem;
  font-size: 1rem;
  border-radius: 1rem;
  text-align: center;
  align-items: center;
  &.active {
    background-color: #cfdec3;
  }
  &.inactive {
    background-color: #f1f5ed;
  }
`;
const ChartContainer = styled.div`
  height: 73vh;
`;

/*
// 기존
[
  {
    "receive_date": 1630337820000,
    "ti_value": 21.0,
    "hi_value": 54.0,
    "ci_value": 362.0,
    "ir_value": 106.0,
    "tl_value": 20.7,
    "ei_value": null,
    "pl_value": null
  },
  
]
*/

export const datasets = [
  {
    dataKey: "temp",
    stroke: "#5f9137",
    name: "온도",
  },
  {
    dataKey: "hum",
    stroke: "#395EF6",
    name: "습도",
  },
  // 평창 hd 데이터 없음
  {
    dataKey: "hd",
    stroke: "#E8C600",
    name: "HD",
  },
  // 평창 이슬점 데이터 없음
  {
    dataKey: "dew",
    stroke: "#ff6384",
    name: "이슬점",
  },
  // 평창 vpd 데이터 없음
  {
    dataKey: "vpd",
    stroke: "#5f9137",
    name: "VPD",
  },
  // {
  //   dataKey: "ir_value",
  //   stroke: "#E8C600",
  //   name: "일사",
  // },
  // {
  //   dataKey: "ei_value",
  //   stroke: "#ff6384",
  //   name: "EC",
  // },
  // {
  //   dataKey: "pl_value",
  //   stroke: "#5f9137",
  //   name: "pH",
  // },
  {
    dataKey: "co2",
    stroke: "#395EF6",
    name: "CO₂",
  },
];

const OPTIONS = [
  // { title: "weekly", code: 3, dateFormat: "MM.DD", milliseconds: 2419000000000000, id: "ai_measuredata_hour" }, // 40주
  {
    title: "daily",
    code: 2,
    dateFormat: "MM/DD",
    milliseconds: 2678400000,
    id: "ai_measuredata_day",
  }, // 일 평균 =>1개월 어치
  {
    title: "hourly",
    code: 1,
    dateFormat: "HH:MM",
    milliseconds: 86400000,
    id: "ai_measuredata_hour",
  }, //시간 평균=> 24 시간
  {
    title: "5minutes",
    code: 0,
    dateFormat: "HH:MM",
    milliseconds: 3600000,
    id: "ai_tranddata",
  }, //실시간 5분마다 =>개  60분
];

// const farm_id = "KRAI200001";
// KRAI000001; // goat demo tomato
// KRAI100001  // asan  엽채류
// KRAI200001  // goat-sub 평창 Epinet
const house_cde = 1;
const nid = 1;

const Data = () => {
  const { t } = useTranslation("accumulatedData");
  const { t: tCommon } = useTranslation("common");
  const { farm_id } = useStore();

  const [greenhoseData, setGreenhouseData] = useState([]);
  const date = new Date().getTime();
  const [dataType, setDataType] = useState(2); // code

  const getFromDate = (milliseconds) => {
    const fromDate = formatDate(date - milliseconds, "YYYY-MM-DD");
    return fromDate;
  };

  let initialValue = datasets.slice(0, 2); // 온도 습도
  const [lines, setLines] = useState(initialValue);
  const [clicked, setClicked] = useState({
    // 버튼 색상 결정
    temp: true,
    hum: true,
    hd: false,
    dew: false,
    vpd: false,
    co2: false,
  });

  // chart lengend 설정
  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <Wrapper>
        {payload.map((entry) => (
          <LegendWrapper key={entry.dataKey}>
            <Circle bc={entry.payload.stroke} />
            <Legend>{t(`legend.${entry.value}`)}</Legend>
          </LegendWrapper>
        ))}
      </Wrapper>
    );
  };

  // chart 설정
  const config = {
    x: "mea_dat",
    options: {
      reversed: false,
      margin: { top: 10, right: 40, left: 0, bottom: 20 },
    },
    data: lines,
  };

  const onClickButton = (key) => {
    if (lines.length > 1) {
      setClicked((cur) => {
        let newCondition = { ...cur };
        newCondition[key] = !newCondition[key];
        return newCondition;
      });

      for (let line of lines) {
        if (line.dataKey === key) {
          setLines(lines.filter((line) => line.dataKey !== key)); // 일치하는 것 찾아서 삭제.
          break;
        } else {
          // 없으면 추가
          const newData = datasets.filter((data) => data.dataKey === key);
          setLines([...lines, ...newData]);
        }
      }
    }
    // 선택된 것 1개면 추가만 가능
    else if (lines.length == 1) {
      if (lines[0].dataKey !== key) {
        setClicked((cur) => {
          let newCondition = { ...cur };
          newCondition[key] = !newCondition[key];
          return newCondition;
        });
        const newData = datasets.filter((data) => data.dataKey === key);
        setLines([...lines, ...newData]);
      }
    }
  };
  useEffect(() => {
    if (farm_id) {
      switch (dataType) {
        case 0: // 실시간
          getGreenhouseDataRealTime(
            OPTIONS[2].id,
            farm_id,
            house_cde,
            getFromDate(OPTIONS[2].milliseconds),
            formatDate(date, "YYYY-MM-DD"),
            nid
          ).then((res) => {
            const formattedData = res
              .map((item) => ({
                ...item,
                mea_dat: getTimeFromDate(item.mea_dat),
              }))
              .reverse();
            setGreenhouseData(formattedData);
          });
          break;
        case 1:
          getGreenhouseData(
            OPTIONS[1].id,
            farm_id,
            house_cde,
            getFromDate(OPTIONS[1].milliseconds),
            formatDate(date, "YYYY-MM-DD")
          ).then((res) => {
            const formattedData = res
              .map((item) => ({
                ...item,
                mea_dat: getTimeFromDate(item.mea_dat),
              }))
              .reverse();
            setGreenhouseData(formattedData);
          });
          break;
        case 2:
          getGreenhouseData(
            OPTIONS[0].id,
            farm_id,
            house_cde,
            getFromDate(OPTIONS[0].milliseconds),
            formatDate(date, "YYYY-MM-DD")
          ).then((res) => {
            const formattedData = res
              .map((item) => ({
                ...item,
                mea_dat: getTimeFromDate(item.mea_dat),
              }))
              .reverse();
            setGreenhouseData(formattedData);
          });
          break;
      }
    }
  }, [farm_id, dataType]); // 데이터 종류 변경될 때마다 온실 데이터 리로드

  useInterval(() => {
    switch (dataType) {
      case 0: // 실시간
        getGreenhouseDataRealTime(
          OPTIONS[2].id,
          farm_id,
          house_cde,
          getFromDate(OPTIONS[2].milliseconds),
          formatDate(date, "YYYY-MM-DD"),
          nid
        ).then((res) => {
          const formattedData = res
            .map((item) => ({
              ...item,
              mea_dat: getTimeFromDate(item.mea_dat),
            }))
            .reverse();
          setGreenhouseData(formattedData);
        });
        break;
      case 1:
        getGreenhouseData(
          OPTIONS[1].id,
          farm_id,
          house_cde,
          getFromDate(OPTIONS[1].milliseconds),
          formatDate(date, "YYYY-MM-DD")
        ).then((res) => {
          const formattedData = res
            .map((item) => ({
              ...item,
              mea_dat: getTimeFromDate(item.mea_dat),
            }))
            .reverse();
          setGreenhouseData(formattedData);
        });
        break;
      case 2:
        getGreenhouseData(
          OPTIONS[0].id,
          farm_id,
          house_cde,
          getFromDate(OPTIONS[0].milliseconds),
          formatDate(date, "YYYY-MM-DD")
        ).then((res) => {
          const formattedData = res
            .map((item) => ({
              ...item,
              mea_dat: getTimeFromDate(item.mea_dat),
            }))
            .reverse();
          setGreenhouseData(formattedData);
        });
        break;
    }
  }, 60000); // 1분에 1번 요청

  return (
    <Container>
      <Title src={Menu_Data} title={t("title")} />
      <OptionWrapper>
        <ButtonWrapper>
          {datasets.map((val) => (
            <Button
              className={clicked[val.dataKey] ? "active" : ""}
              key={val.dataKey}
              onClick={() => {
                onClickButton(val.dataKey);
              }}
            >
              {t(`data.${val.name}`)}
            </Button>
          ))}
        </ButtonWrapper>
        <div id="legend-container" />
        <TimeButtonContainer>
          <TimeButtonWrapper>
            {OPTIONS.map((opt) => (
              <TimeButton
                key={opt.title}
                className={dataType === opt.code ? "active" : "inactive"}
                onClick={() => {
                  setDataType(opt.code);
                }}
              >
                {tCommon(`timeOptions.${opt.title}`)}
              </TimeButton>
            ))}
          </TimeButtonWrapper>
        </TimeButtonContainer>
      </OptionWrapper>
      <ChartContainer>
        {greenhoseData ? (
          <Chart
            data={greenhoseData}
            config={config}
            renderLegend={renderLegend}
            dataFormat={OPTIONS.find((opt) => opt.code === dataType).dateFormat}
          />
        ) : (
          <>
            <div></div>
          </>
        )}
      </ChartContainer>
    </Container>
  );
};

export default Data;
