import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import useStore from "../../store/store";
import RmseSocket from "./RmseSocket";
import Chart from "../../components/Chart";
import Modal from "../../components/Modal";
import Card from "../../components/Card";
import ModalSetupModel from "./ModalSetupModel";

import { getRmse } from "../../api/RmseApi";
import { getModelList, getModel } from "../../api/ModelApi";

import General_File from "../../assets/img/General_File.svg";
import General_Settings from "../../assets/img/General_Settings.svg";
import Info_Square from "../../assets/img/Info_Square.svg";

const Container = styled.div`
  padding: 1rem 0 0 0;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
const TitleButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  display: flex;
  margin: 0.5rem 0;
  align-items: center;
`;
const Icon = styled.img`
  height: ${(props) => props.height || "1rem"};
  padding-left: 0.5rem;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f1f5ed;
  border-radius: 1rem;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  min-width: 2rem;
  padding: 0.2rem 0.3rem;
  font-size: 0.75rem;
  border-radius: 1rem;
  text-align: center;
  align-items: center;
  &.active {
    background-color: #cfdec3;
  }
  &.inactive {
    background-color: #f1f5ed;
  }
`;
const Farm = styled.div`
  border-bottom: 2px solid #5f9137;
`;
const ChartWrapper = styled.div`
  position: relative;
  left: -2rem;
  height: 45vh;
  min-width: 45vw;
`;
const ChosenModelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.5rem;
  background-color: #f7f9f5;
  margin-top: 1.5rem;
  .icon-text-wrapper {
    align-items: center;
    display: flex;
  }
  .text-wrapper {
    padding-left: 0.5rem;
  }
  .title {
    font-size: 1.125rem;
  }
  .description {
  }
`;
const PL_MODEL = [
  { pl_model: 0, name: "A 로메인" },
  { pl_model: 1, name: "A 토마토" },
];
const OPTIONS = [
  { title: "weekly", code: 3, dateFormat: "MM.DD" },
  { title: "daily", code: 2, dateFormat: "MM.DD" },
  { title: "hourly", code: 1, dateFormat: "HH" },
  { title: "5minutes", code: 0, dateFormat: "HH:MM" },
];

const HOUSE_CODE = 1;

const AiController = () => {
  const { t: tCommon } = useTranslation("common");
  const { t: tDashboard } = useTranslation("dashboard");
  const { t } = useTranslation("dashboard", { keyPrefix: "aiController" });

  const { api_key, farm_id, pl_code, pl_model } = useStore();

  const [controller, setController] = useState(-1);
  const [modelList, setModelList] = useState([]); // 모델 목록
  const [model, setModel] = useState(-1);
  const socket = RmseSocket();
  const [rmse, setRmse] = useState([]);

  // 모델 선택하는 모달
  const [modelModal, setModelModal] = useState(false);
  const [chosenVal, setChosenVal] = useState("");
  const [chosenId, setChosenId] = useState(pl_code);

  const [state, setState] = useState({});

  // modal창 띄우기
  const [modal, setModal] = useState(false);
  const handleModal = () => {
    setModal(!modal);
  };

  const handleModelModal = () => {
    setModelModal(!modelModal);
  };

  const handleSaveModal = () => {
    setModel({ pl_model: chosenId, name: chosenVal });
    setModelModal(!modelModal); // 모달 닫기
  };

  const content = useMemo(
    () =>
      modal && (
        <Card
          title={t("rmse.title")}
          close={handleModal}
          text={t("rmse.text")}
          note={t("rmse.note")}
        />
      ),
    [modal]
  );

  const config = useMemo(
    () => ({
      x: "time",
      options: {
        type: "basis",
        dot: false,
        reversed: true,
      },
      data: [
        {
          dataKey: "rmse",
          stroke: "#5f9137",
          name: "rmse",
        },
      ],
    }),
    []
  );

  const handleClickRmseType = useCallback((datatype) => {
    const date = new Date().getTime();
    getRmse(api_key, farm_id, pl_code, pl_model, date, datatype).then((res) => {
      // setRmse(res)
      switch (datatype) {
        case 0: // 5분이면 60개
          setRmse(res.slice(-60)); // 60보다 작으면 알아서 전체 배열 반환
          break;
        case 1: // 1시간 24개
          setRmse(res.slice(-24));
          break;
        case 2: // 일  한 달
          setRmse(res.slice(-31));
          break;
        case 3: // 주 40주
          setRmse(res.slice(-40));
          break;
      }
    });
  }, []);

  useEffect(() => {
    // 모델 기본 정보 가져오기(어떤 모드로 했는지까지)
    getModel(api_key, farm_id, pl_code)
      .then((res) => {
        setState((cur) => {
          let newState = { ...cur };
          newState.pl_model = res.pl_model;
          newState.model_name = res.model_name;
          newState.model_option = res.model_option;
          newState.options = res.options;
          newState.priority = res.priority;
          newState.relearn = res.relearn;
          return newState;
        });
      })
      .catch((error) => console.error("Error getting model:", error));
  }, [farm_id, model]);
  return (
    state.model_name && (
      <Container>
        <Header>
          <TitleButtonWrapper>
            <Title>
              <div>{t("rmse.title")}</div>
              <Icon src={Info_Square} alt="RMSE?" onClick={handleModal} />
            </Title>
            <ButtonWrapper>
              {OPTIONS.map((opt) => (
                <Button
                  key={opt.code}
                  className={controller === opt.code ? "active" : "inactive"}
                  onClick={() => {
                    setController(opt.code);
                    handleClickRmseType(opt.code);
                  }}
                >
                  {tCommon(`timeOptions.${opt.title}`)}
                </Button>
              ))}
              <Button
                className={controller === -1 ? "active" : "inactive"}
                onClick={() => {
                  setController(-1);
                }}
              >
                {tCommon("timeOptions.realTime")}
              </Button>
            </ButtonWrapper>
          </TitleButtonWrapper>
          <Farm>{tDashboard(`farm.${farm_id}`)}</Farm>
        </Header>
        <ChartWrapper>
          {controller === -1 ? (
            <Chart data={socket} config={config} dataFormat="MM:SS" />
          ) : (
            rmse && (
              <Chart
                data={rmse}
                config={config}
                dataFormat={
                  OPTIONS.filter((opt) => opt.code === controller)[0].dateFormat
                }
              />
            )
          )}
        </ChartWrapper>
        <ChosenModelWrapper>
          <div className="icon-text-wrapper">
            <Icon src={General_File} height="2rem" />
            <div className="text-wrapper">
              <div className="title">{t(`model.${state.model_name}`)}</div>
              <div className="description">
                {t(
                  `mode.${
                    state.options.find((opt) => opt.id === state.model_option)
                      ?.name
                  }`
                )}
              </div>
            </div>
          </div>
          <Icon
            src={General_Settings}
            height="2rem"
            onClick={() => {
              setModelModal(!modelModal);
              handleModelModal();
            }}
          />
        </ChosenModelWrapper>
        {modal && (
          <Modal
            show={modal}
            content={content}
            reject={handleModal}
            style={{ height: "13rem" }}
          />
        )}
        {modelModal && (
          <ModalSetupModel
            open={handleModelModal}
            close={() => {
              setModelModal(false);
            }}
            execute={handleSaveModal}
            // model={model}
            house_cde={HOUSE_CODE}
            pl_code={pl_code}
            {...state}
          />
        )}
      </Container>
    )
  );
};

export default AiController;
