import * as utils from "./utils.js";
import * as actKnd from "./act_knd.js";

export function changeOpenrate(data) {
  var agid_akCnt = utils.pad(data[0].toString(2), 8);
  var akCnt = parseInt(agid_akCnt.substring(4, 8), 2);
  let pos = 1;
  for (let akidx = 0; akidx < akCnt; akidx++) {
    let akDataSize = data[pos++];
    let akData = data.slice(pos, pos + akDataSize);
    var akid_alCnt = utils.pad(akData[0].toString(2), 8);
    var akid = parseInt(akid_alCnt.substring(0, 4), 2);
    pos = pos + akDataSize;
    actKnd.changeOpenrate(akData);
  }
}
