import * as utils from "./utils.js";
import * as actwindow from "./actWindow.js";

export function changeOpenrate(data) {
  var alid_lsCnt = utils.pad(data[0].toString(2), 8);
  var lsCnt = parseInt(alid_lsCnt.substring(4, 8), 2);
  let pos = 1;
  for (let lsidx = 0; lsidx < lsCnt; lsidx++) {
    let id_status = utils.pad(data[pos++].toString(2), 8);
    var lsid = parseInt(id_status.substring(0, 4), 2);
    var status = parseInt(id_status.substring(4, 8), 2);
    let openrate = data[pos++];
    actwindow.changeOpenrate(status, openrate);
  }
}
