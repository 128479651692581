export function pad(n, width) {
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join("0") + n;
}

export function byte2Int(x) {
  // Check if x is undefined or null
  if (x === undefined || x === null || x.length < 2) {
    throw new Error(
      "Invalid input: x must be an array with at least two elements"
    );
  }

  let val = 0;

  val = (x[0] << 8) | (x[1] & 0x00ff);
  let isMinus = x[0] >> 7 == 1 ? -1 : 1;
  if (isMinus < 0) {
    let pad16 = pad(val.toString(2), 16);
    let compeVal = "";
    for (let kk = 0; kk < 16; kk++) {
      compeVal += pad16[kk] == "0" ? "1" : "0";
    }
    val = Number(parseInt(compeVal, 2)) + 1;
  }

  val = val * isMinus;
  return val;
}
