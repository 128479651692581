const WINDOWS_KND = ["01", "02", "03", "04"];

export const categorizeActGroups = (data) => {
  const categorizedData = {
    windows: [],
    etc: [],
  };

  data.forEach((act) => {
    if (WINDOWS_KND.includes(act.knd)) {
      categorizedData.windows.push(act);
    } else {
      categorizedData.etc.push(act);
    }
  });

  return categorizedData;
};

export const transformData = (categorizedData) => {
  const transformedData = {
    windows: [],
    etc: [],
  };

  categorizedData.windows?.forEach((item) => {
    item.act_knd?.forEach((actKnd) => {
      actKnd.act_layer?.forEach((actLayer) => {
        actLayer.act_layer_sub?.forEach((sub) => {
          transformedData.windows.push({
            title:
              sub.nam === "전체" ? `${actKnd.nam}` : `${actKnd.nam} ${sub.nam}`,
            agID: item.key,
            akID: actKnd.key,
            knd: +actKnd.knd,
            layerID: actLayer.key,
            layersubID: sub.key,
            oppstate: sub.os,
            openRate: sub.or,
          });
        });
      });
    });
  });

  categorizedData.etc?.forEach((item) => {
    item.act_knd?.forEach((actKnd) => {
      actKnd.act_onoff?.forEach((onoff) => {
        transformedData.etc.push({
          title:
            onoff.nam === "전체"
              ? `${actKnd.nam}`
              : `${actKnd.nam} ${onoff.nam}`,
          agID: item.key,
          akID: actKnd.key,
          knd: +actKnd.knd,
          onoffID: onoff.key,
          oppstate: onoff.oppstate,
        });
      });
    });
  });

  return transformedData;
};

export const getActGroupPeriods = (data) => {
  const knd = [];
  const actGrp = [];

  data.forEach((act) => {
    if (knd.every((el) => el !== act.knd)) {
      knd.push(act.knd);
      actGrp.push({
        nam: act.nam,
        sid: act.sid,
        sid2: act.sid2,
        sm: act.sm,
        sm2: act.sm2,
        periods: act.actgroup_perioad,
      });
    }
  });
  return actGrp;
};
