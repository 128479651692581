import { C_ISLOCAL, CH_AUTOMANUAL, CH_ONOFF } from "./Consts";
const messageQueue = new Map();

const processQueue = (socket) => {
  const queue = messageQueue.get(socket);
  if (queue) {
    while (queue.length > 0 && socket.readyState === WebSocket.OPEN) {
      const buffer = queue.shift();
      socket.send(buffer);
    }
  }
};

const sendMessage = (socket, buffer) => {
  if (socket.readyState === WebSocket.OPEN) {
    socket.send(buffer);
  } else {
    if (!messageQueue.has(socket)) {
      messageQueue.set(socket, []);
      socket.addEventListener("open", () => processQueue(socket), {
        once: true,
      });
    }
    messageQueue.get(socket).push(buffer);
  }
};

export function operation(socket, house_cde, agid, akid, onoffid, opp) {
  let arrayBuffer = new ArrayBuffer(7);
  let buffer = new Uint8Array(arrayBuffer);

  buffer[0] = CH_ONOFF;
  buffer[1] = 5;
  buffer[2] = Number(house_cde);
  buffer[3] = Number(agid);
  buffer[4] = Number(akid);
  buffer[5] = Number(onoffid);
  buffer[6] = Number(opp);

  sendMessage(socket, buffer);
}

// islocal => 1:local, 0:remote
export function switchControl(socket, house_cde, isLocal) {
  let arrayBuffer = new ArrayBuffer(3);
  let buffer = new Uint8Array(arrayBuffer);

  buffer[0] = C_ISLOCAL;
  buffer[1] = Number(house_cde);
  buffer[2] = isLocal === "Y" ? 1 : 0; // 1 : 온실 로컬 제어  0 온실 원격 제어

  sendMessage(socket, buffer);
}

export function switchIsAuto(socket, house_cde, isAuto) {
  let arrayBuffer = new ArrayBuffer(7);
  let buffer = new Uint8Array(arrayBuffer);

  buffer[0] = CH_AUTOMANUAL;
  buffer[1] = Number(house_cde);
  buffer[2] = Number(isAuto);
  sendMessage(socket, buffer);
}
