import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { getJournalList, deleteJournal } from "../../api/JournalApi";

import useStore from "../../store/store";
import ExclamationGreen from "../../assets/img/Notification_Exclamation_Green.svg";
import ModalConfirm from "../../pages/Dashboard/ModalConfirm";

import Journal_Exclamation from "../../assets/img/Journal_Exclamation.svg";
import General_Arrow_Forward from "../../assets/img/General_Arrow_Forward.svg";
import General_Arrow_Back from "../../assets/img/General_Arrow_Back.svg";
import General_V from "../../assets/img/General_V.svg";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin: 3.9rem 1rem 1rem 1rem;
  padding: 1rem 3rem 1rem 1rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  a {
    text-decoration: none;
    color: #4d4d4d;
  }
`;
const Title = styled.div`
  font-size: 1.125rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;
const Button = styled.div`
  display: flex;
  border: 1px solid #5f9137;
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: #4d4d4d;
  }
`;

const JournalListItem = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ selected }) => (selected ? "#CFDEC3" : "#f7f9f5")};
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  .title-wrapper {
    display: flex;
    align-items: center;
    .exclamation {
      width: 1.5rem;
    }
    .title {
      padding-left: 1rem;
      cursor: pointer;
    }
  }
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 1rem;
  div {
    padding-left: 0.5rem;
  }
`;
const Icon = styled.img`
  padding-left: 0.5rem;
  display: flex;
  width: 1.125rem;
  cursor: pointer;
`;
const MessageIcon = styled.img`
  width: "3rem";
  padding-right: 1rem;
`;

const EmptyMessageWrapper = styled.div`
  background-color: #f7f9f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  height: 74vh;
  .title {
    font-size: 1.25rem;
    padding: 0.5rem 0;
  }
`;

const Monitoring = () => {
  const { t } = useTranslation("monitoring");

  const { api_key, farm_id, pl_code } = useStore();
  const [deleteButton, setDeleteButton] = useState(false); // 삭제하기
  const [deleteList, setDeleteList] = useState([]); // 삭제할 리스트
  const [journals, setJournals] = useState([]);
  const [JournalCount, setJournalCount] = useState(0); // api 수정하기
  const [page, setPage] = useState(1);

  const startIndex = (page - 1) * 20 + 1;
  const endIndex = Math.min(page * 20, JournalCount);

  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);

  const handleClickDeleteButton = () => {
    setDeleteButton(true);
  };

  const handleSelectItem = (journal_id) => {
    if (deleteList.includes(journal_id)) {
      // 목록에 이미 있으면 목록에서 삭제
      setDeleteList(deleteList?.filter((item) => item !== journal_id));
    } else {
      setDeleteList([...deleteList, journal_id]); // 없으면 추가
    }
  };
  // 삭제 확인 모달 제어
  const handleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDeleteJournal = async () => {
    try {
      for (const journal_seq of deleteList) {
        await deleteJournal(api_key, farm_id, pl_code, journal_seq);
      }
      setDeleteList([]);
      setDeleteModal(!deleteModal);
      setDeleteButton(!deleteButton);
      fetchJournals(page);
    } catch (error) {
      console.error("Failed to delete journal:", error);
    }
  };

  const handleClickBack = async () => {
    if (page > 1) {
      let nextList = await getJournalList(api_key, farm_id, pl_code, page - 1);
      setPage((prev) => prev - 1);
      setJournals(nextList.journal_list);
    }
  };

  const handleClickForward = async () => {
    let nextList = await getJournalList(api_key, farm_id, pl_code, page + 1);
    if (nextList.journal_list.length !== 0) {
      // 다음 페이지 결과값 있다면
      setPage((prev) => prev + 1);
      setJournals(nextList.journal_list);
    }
  };

  const handleClickListItem = (journal_seq) => {
    navigate(`journals/${journal_seq}`, {
      state: { journal_seq: journal_seq },
    });
  };

  const fetchJournals = async (page) => {
    try {
      const res = await getJournalList(api_key, farm_id, pl_code, page);
      setJournals(res.journal_list);
      setJournalCount(res.total_counts);
    } catch (error) {
      console.error("Failed to fetch journals:", error);
    }
  };

  useEffect(() => {
    fetchJournals(page);
  }, [page]);

  return (
    <Container>
      <TitleWrapper>
        <Title>{t("title")}</Title>
        <ButtonWrapper>
          {deleteButton ? (
            <>
              <Button
                onClick={
                  deleteList.length !== 0 ? () => handleDeleteModal() : null
                }
              >
                {t("general.deleteSelected")}
              </Button>
              <Button
                onClick={() => {
                  setDeleteList([]);
                  setDeleteButton(!deleteButton);
                }}
              >
                {t("general.close")}
              </Button>
            </>
          ) : (
            <>
              <Link to="journals/create">
                <Button>{t("general.create")}</Button>
              </Link>
              {journals && (
                <Button onClick={handleClickDeleteButton}>
                  {t("general.delete")}
                </Button>
              )}
            </>
          )}
        </ButtonWrapper>
      </TitleWrapper>
      {journals ? (
        <>
          <PaginationWrapper>
            <Icon src={General_Arrow_Back} onClick={handleClickBack} />
            <div>
              {startIndex} - {endIndex} of {JournalCount}
            </div>
            <Icon src={General_Arrow_Forward} onClick={handleClickForward} />
          </PaginationWrapper>
          <ContentWrapper>
            {journals.map((journal) =>
              deleteButton ? (
                <JournalListItem
                  key={journal.id}
                  selected={deleteList.includes(journal.id)}
                  onClick={() => handleSelectItem(journal.id)}
                >
                  <div className="title-wrapper">
                    <img
                      src={
                        deleteList.includes(journal.id)
                          ? General_V
                          : Journal_Exclamation
                      }
                      className="exclamation"
                    />
                    <div className="title">
                      {journal.date} {t("title")}
                    </div>
                  </div>
                </JournalListItem>
              ) : (
                <JournalListItem
                  key={journal.id}
                  onClick={() => handleClickListItem(journal.id)}
                >
                  <div className="title-wrapper">
                    <img src={Journal_Exclamation} className="exclamation" />
                    <div className="title">
                      {journal.date} {t("title")}
                    </div>
                  </div>
                </JournalListItem>
              )
            )}
          </ContentWrapper>
        </>
      ) : (
        <EmptyMessageWrapper>
          <MessageIcon src={ExclamationGreen} />
          <div className="title">{t("notification")}</div>
        </EmptyMessageWrapper>
      )}
      {deleteModal && (
        <ModalConfirm
          title={t("modal.title")}
          close={handleDeleteModal}
          content={`${t("modal.content1")}${deleteList.length}${t(
            "modal.content2"
          )}`}
          execute={handleDeleteJournal}
        />
      )}
    </Container>
  );
};

export default Monitoring;
