import * as utils from "./utils.js";
import * as house from "./house.js";
import * as weatherstation from "./weatherstation.js";

export const parseData = (rev_data) => {
  //온실내 센서
  let hcnt = rev_data[1];

  //======================================
  //json data 생성 시작
  let data = {
    //복수개의 온실
    houseCnt: hcnt,
    house: [],
    weatherstation: {},
  };
  //======================================

  let pos = 2;
  for (let hidx = 0; hidx < hcnt; hidx++) {
    let hc = rev_data[pos++];
    let hDataSize = rev_data[pos++];
    if (hDataSize > 0) {
      //======================================
      //온실 아이템 생성
      let item_house = {};

      //온실 하우스 ID
      item_house.houseID = hc;
      data.house.push(item_house);
      //======================================

      let hData = rev_data.slice(pos, pos + hDataSize);
      //======================================
      //item_house -> last parameter
      //======================================
      //console.log(house);
      house.refreshData(hData, item_house);
      pos = pos + hDataSize;
    }
  }
  //기상대
  let isws = rev_data[pos++];
  if (isws === 22) {
    let sCnt = rev_data[pos++];
    //======================================
    //기상대에 붙어있는 센서의 갯수
    data.weatherstation.sensorCount = sCnt;
    //복수개의 센서
    data.weatherstation.sensors = [];
    //======================================
    for (let sidx = 0; sidx < sCnt; sidx++) {
      let sid = rev_data[pos++];
      let ff = rev_data[pos++];
      let ss = rev_data[pos++];
      let buf_value = [ff, ss];
      let value = utils.byte2Int(buf_value) / 10.0;

      //======================================
      //기상대 센서 생성
      let item_sensor = {};
      data.weatherstation.sensors.push(item_sensor);
      //======================================

      weatherstation.refreshData(sid, value, item_sensor);
    }
  }
  //일출일몰시간
  let sun = rev_data[pos++];
  if (sun === 23) {
    data.sunrise =
      utils.pad(rev_data[pos++], 2) + ":" + utils.pad(rev_data[pos++], 2);
    data.sunset =
      utils.pad(rev_data[pos++], 2) + ":" + utils.pad(rev_data[pos++], 2);
  }

  return data;
};
