import React from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  background: ${({ checked }) => (checked === "Y" ? "#E7E8E4" : "#5F9137")};
  display: block;
  border-radius: 100px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: ${({ checked }) => (checked === "Y" ? "3px" : "calc(55% - 1px)")};
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 50%;
    transition: 0.3s;
  }
`;

const ToggleSwitch = ({ state, setState }) => {
  return (
    <StyledLabel htmlFor="checkbox" checked={state}>
      <input
        id="checkbox"
        type="checkbox"
        checked={state}
        onChange={setState}
      />
    </StyledLabel>
  );
};

export default ToggleSwitch;
