import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getHouse } from "../../api/SocketApi.js";
import useStore from "../../store/store.js";
import { getActGroupPeriods } from "./dataTransform";
import AutoControllerForm from "./AutoControllerForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.div`
  font-size: 1.125rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #5f9137;
  border-radius: 4px;
  height: 1.875rem;
  padding: 0 0.4rem;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 1rem 0;
`;
const Subtitle = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  padding-bottom: 0.5rem;
`;
const Card = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  border: 0.5px solid #e2e4e5;
  border-radius: 3px;
  padding: 0.375rem 0;
  margin-bottom: 0.3rem;
`;

// 정확한 것 아님, 받아야 함
const TKND = {
  1: "일출 전",
  2: "일출 후",
  3: "",
  4: "",
  5: "",
  6: "정의",
  7: "일몰 전",
  8: "일몰",
  9: "일몰 후",
};

const AutoController = () => {
  const { farm_id } = useStore();
  const [form, setForm] = useState(false);
  const [actGroup, setActGroup] = useState([]);

  const handleCreateCondition = () => {
    setForm(true);
  };

  useEffect(() => {
    if (farm_id) {
      getHouse(farm_id)
        .then((res) => {
          // 여기다 데이터 가공해서 넣기
          setActGroup(getActGroupPeriods(res.house[0].actgroup));
        })
        .catch((error) =>
          console.error("Failed to fetch act group data:", error)
        );
    }
  }, [farm_id]);
  return (
    <Container>
      <TitleWrapper>
        <Title>시간별 조건제어 설정</Title>
        {form ? null : (
          <ButtonWrapper>
            <Button onClick={handleCreateCondition}>+ 추가하기</Button>
            <Button>- 삭제하기</Button>
          </ButtonWrapper>
        )}
      </TitleWrapper>
      <ContentWrapper>
        {form ? (
          <AutoControllerForm />
        ) : (
          <>
            {/* <Card>
              <div>환기</div>
              <div>일출 전</div>
              <div>05:00</div>
              <div>19.0</div>
              <div>70%</div>
            </Card> */}
            {actGroup?.map((act) => (
              // <div> 태그를 삽입하는 위치
              <div key={act.nam}>
                <Subtitle>
                  <div>구분</div>
                  <div>작동</div>
                  <div>작동 시각</div>
                  <div>{act.sm}</div>
                  <div>{act.sm2}</div>
                  {/* 이거 아무래도 우선순위에 따라서 위치 바뀌거나 할 듯 */}
                </Subtitle>
                {act.periods?.map((period) => (
                  <Card key={period.tknd}>
                    <div>{act.nam}</div>
                    <div>{TKND[period.tknd]}</div>
                    <div>{period.tinc}</div>
                    {/* sval이 secondary 라고 했는데 안맞는 듯 */}
                    <div>{period.sval}</div>
                    <div>{period.hm}</div>
                  </Card>
                ))}
              </div>
            ))}
          </>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default AutoController;
