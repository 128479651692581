import { instance } from "./Axios";

export const getModelList = async (api_key, farm_id, pl_code) => {
  const response = await instance.post("dbserver/goatfarm/models", {
    api_key: api_key,
    farm_id: farm_id,
    pl_code: pl_code,
  });
  return response.data;
};

export const deleteModel = async (api_key, farm_id, pl_code, pl_model) => {
  const response = await instance.post(`dbserver/goatfarm/models/${pl_code}`, {
    api_key: api_key,
    farm_id: farm_id,
    pl_code: pl_code,
    pl_model: pl_model,
  });
  return response.data;
};

export const editModel = async (
  api_key,
  farm_id,
  pl_code,
  pl_model,
  model_name,
  model_option,
  priority,
  relearn
) => {
  const response = await instance.put(`dbserver/goatfarm/models/${pl_code}`, {
    api_key: api_key,
    farm_id: farm_id,
    pl_code: pl_code,
    pl_model: pl_model,
    model_name: model_name,
    model_option: model_option,
    priority: priority,
    relearn: relearn,
  });
  return response.data;
};

export const getModel = async (api_key, farm_id, pl_code) => {
  const response = await instance.post(`dbserver/goatfarm/models/${pl_code}`, {
    api_key: api_key,
    farm_id: farm_id,
  });
  return response.data;
};
