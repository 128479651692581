import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div``;

const TitleButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
  border: 1px solid #e2e4e5;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 0.875rem;
  .title {
    display: flex;
    align-items: center;
  }
`;

const ProgressWrapper = styled.div`
  width: 100%;
  height: 6px;
  background-color: #d9d9d9;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: hidden;
`;

const Progress = styled.div`
  width: ${(props) => props.width}%;
  height: 6px;
  padding: 0;
  text-align: center;
  background-color: #5f9137;
`;

const ButtonWrapper = styled.div`
  display: flex;
  height: 1.3rem;
  background-color: #f3f5f7;
  border-radius: 3px;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 2.5rem;
  height: 1.5rem;
  border-radius: 3px;
  text-align: center;
  align-items: center;
  &.active {
    background-color: #5f9137;
    color: #ffffff;
  }
  &.isLocal {
    background-color: #afafaf;
    color: #ffffff;
  }
  &.inactive {
    background-color: #f3f5f7;
    color: #afafaf;
  }
`;

const ControllerWindows = ({
  title,
  houseID,
  agID,
  akID,
  layerID,
  layersubID,
  oppstate,
  openRate,
  isLocal,
  handleClick,
}) => {
  const { t } = useTranslation("dashboard", {
    keyPrefix: "manualController",
  });

  const [status, setStatus] = useState("stop");
  const [percent, setPercent] = useState(null);

  useEffect(() => {
    setPercent(openRate);
  }, [openRate]);

  return (
    <Container>
      <TitleButtonWrapper>
        <div className="title">{t(`controllers.${title}`)}</div>
        <ButtonWrapper>
          <Button
            className={
              status === "close"
                ? isLocal === "Y"
                  ? "isLocal"
                  : "active"
                : "inactive"
            }
            onClick={
              isLocal === "N"
                ? () => {
                    setStatus("close");
                    handleClick(
                      title,
                      houseID,
                      agID,
                      akID,
                      layerID,
                      layersubID,
                      2, // oppState : 닫히고 있다
                      0 // openRate
                    );
                  }
                : null
            }
          >
            {t("buttons.close")}
          </Button>
          <Button
            className={
              status === "stop"
                ? isLocal === "Y"
                  ? "isLocal"
                  : "active"
                : "inactive"
            }
            onClick={
              isLocal === "N"
                ? () => {
                    setStatus("stop");
                    handleClick(
                      title,
                      houseID,
                      agID,
                      akID,
                      layerID,
                      layersubID,
                      0 // 정지
                    );
                  }
                : null
            }
          >
            {t("buttons.stop")}
          </Button>
          <Button
            className={
              status === "open"
                ? isLocal === "Y"
                  ? "isLocal"
                  : "active"
                : "inactive"
            }
            onClick={
              isLocal === "N"
                ? () => {
                    setStatus("open");
                    handleClick(
                      title,
                      houseID,
                      agID,
                      akID,
                      layerID,
                      layersubID,
                      1, // 열리고 있다.
                      100
                    );
                  }
                : null
            }
          >
            {t("buttons.open")}
          </Button>
        </ButtonWrapper>
      </TitleButtonWrapper>
      <ProgressWrapper>
        <Progress width={percent} color={status} />
      </ProgressWrapper>
    </Container>
  );
};

export default ControllerWindows;
