import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";

i18n
  .use(HttpBackend)
  .use(LanguageDetector) // 사용자 언어 탐지
  .use(initReactI18next) // i18n 객체를 react-18next에 전달
  .init({
    // for all options read: https://www.i18next.com/overview/configuration-options
    // backend: {
    // build 후 해당 경로에 직접 추가하거나.. front server 에 올리기 ... 지금은 backend 서버 경로임
    //   loadPath: `${process.env.REACT_APP_URL}static/locales/{{lng}}/{{ns}}.json`, // test server
    // },
    debug: true,
    ns: [
      "dashboard",
      "common",
      "accumulatedData",
      "monitoring",
      "notification",
      "settings",
    ], // locales namespace
    defaultNS: "dashboard",

    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    // local에 있을 때만 설정
    resources: {
      en: {
        common: require("./en/common.json"),
        dashboard: require("./en/dashboard.json"),
        accumulatedData: require("./en/accumulatedData.json"),
        monitoring: require("./en/monitoring.json"),
        notification: require("./en/notification.json"),
        settings: require("./en/settings.json"),
      },
      ko: {
        common: require("./ko/common.json"),
        dashboard: require("./ko/dashboard.json"),
        accumulatedData: require("./ko/accumulatedData.json"),
        monitoring: require("./ko/monitoring.json"),
        notification: require("./ko/notification.json"),
        settings: require("./ko/settings.json"),
      },
    },
  });

export default i18n;
