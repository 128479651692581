import axios from "axios";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export const tnsInstance = axios.create({
  baseURL: "/tns/",
  headers: {
    "Content-type": "application/json",
  },
});

// export const mockInstance = axios.create({
//   baseURL:
//     "https://8d4634c9-b40b-4458-879d-15817f022348.mock.pstmn.io/api/v1/dbserver/goatfarm/",
//   headers: {
//     "Content-type": "application/json",
//   },
// });
