// socket.js
import React, { useEffect, useState, createContext, useContext } from "react";
import {
  C_WEB_CLIENT_CONNECTED,
  C_WEB_CLIENT_CONNECTED_COMPLETE,
  R_DATA,
  CH_ONOFF,
  CH_LAYER_SUB_STOP,
  CH_OPPFIT,
  CH_OPENRATE,
  C_ISLOCAL,
  CH_AUTOMANUAL,
  AI_PRIORITY,
  AI_CHOOSE,
} from "./Consts";

import * as utils from "./utils.js";
import * as house from "./house.js";
import * as weatherstation from "./weatherstation.js";
import { parseData } from "./dataParsing";
import * as actonoff from "./actOnOff.js";
import * as actwindow from "./actWindow.js";
import useStore from "../store/store.js";
import { getFarmConfig } from "../utils/getFarmConfig.js";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { initial_farm_id, farm_id, socket_url } = useStore();
  const [farmConfig, setFarmConfig] = useState(getFarmConfig(initial_farm_id));
  const [data, changeData] = useState({});
  const [socketData, setSocketData] = useState({});
  const [onOffController, setOnOffController] = useState({});
  const [windowController, setWindowController] = useState({});
  const [socketIsLocal, setSocketIsLocal] = useState(false);
  const [isAi, setIsAi] = useState(-1);
  const [socket, setSocket] = useState(null);

  let rConnected = function (serverkey) {
    if (!socket) {
      console.error("Socket is not initialized");
      return;
    }
    let arrayBuffer = new ArrayBuffer(3);
    let buffer = new Uint8Array(arrayBuffer);
    buffer[0] = C_WEB_CLIENT_CONNECTED_COMPLETE;
    buffer[1] = serverkey + 7;
    buffer[2] = 0;
    socket.send(buffer);
  };

  const connectWebSocket = (socketUrl) => {
    let newSocket = new WebSocket(socketUrl);

    newSocket.onopen = () => {
      let arrayBuffer = new ArrayBuffer(1);
      let buffer = new Uint8Array(arrayBuffer);
      buffer[0] = C_WEB_CLIENT_CONNECTED;
      newSocket.send(buffer);
    };

    newSocket.onmessage = (message) => {
      const blob = message.data;
      let fileReader = new FileReader();
      fileReader.onload = function (event) {
        const arrayBuffer = event.target.result;
        let buffer = new Uint8Array(arrayBuffer);
        onReceive(buffer);
      };
      fileReader.readAsArrayBuffer(blob);
    };

    newSocket.onerror = (error) => {
      console.error("WebSocket 오류: " + error);
    };

    newSocket.onclose = (event) => {
      console.log("WebSocket 연결이 닫혔습니다.");
    };

    setSocket(newSocket);
  };

  useEffect(() => {
    if (farm_id) {
      setFarmConfig(getFarmConfig(farm_id));
    }
  }, [farm_id]);

  useEffect(() => {
    if (socket_url) {
      if (socket) {
        socket.close();
      }
      connectWebSocket(socket_url);
    }
  }, [socket_url, farm_id]);

  const onReceive = (rev_data) => {
    const cmd = rev_data[0];
    let hc_agid,
      houseID,
      agID,
      akID,
      akid_layerid,
      layerID,
      layerSubID,
      oppstate,
      openRate,
      isLocal,
      isAuto,
      aiDone;

    switch (cmd) {
      case C_WEB_CLIENT_CONNECTED:
        rConnected(rev_data[1]);
        break;
      case C_WEB_CLIENT_CONNECTED_COMPLETE:
        console.log("socket connected");
        break;
      case R_DATA:
        const parsedData = parseData(rev_data);
        changeData(parsedData);
        break;
      case CH_ONOFF:
        //1 nth byte
        hc_agid = utils.pad(rev_data[1].toString(2), 8);
        //상위 4bit houseID
        houseID = parseInt(hc_agid.substring(0, 4), 2);
        //하위 4bit agID
        agID = parseInt(hc_agid.substring(4, 8), 2);

        //2 nth byte
        let akid_onoffid = utils.pad(rev_data[2].toString(2), 8);
        //상위 4bit akID
        akID = parseInt(akid_onoffid.substring(0, 4), 2);
        //하위 4bit onoffID
        let onoffID = parseInt(akid_onoffid.substring(4, 8), 2);
        oppstate = rev_data[3];

        setOnOffController({
          houseID: houseID,
          agID: agID,
          akID: akID,
          onoffID: onoffID,
          oppstate: oppstate,
        });
        console.log("houseID : ", houseID);
        console.log("agID : ", agID);
        console.log("akID : ", akID);
        console.log("onoffID : ", onoffID);
        console.log("oppstate : ", oppstate);
        break;
      case CH_LAYER_SUB_STOP:
        //1 nth byte
        hc_agid = utils.pad(rev_data[1].toString(2), 8);
        //상위 4bit houseID
        houseID = parseInt(hc_agid.substring(0, 4), 2);
        //하위 4bit agID
        agID = parseInt(hc_agid.substring(4, 8), 2);

        //2 nth byte
        akid_layerid = utils.pad(rev_data[2].toString(2), 8);
        //상위 4bit akID
        akID = parseInt(akid_layerid.substring(0, 4), 2);
        //하위 4bit onoffID
        layerID = parseInt(akid_layerid.substring(4, 8), 2);

        layerSubID = rev_data[3];
        oppstate = parseInt(rev_data[4]);
        openRate = parseInt(rev_data[5]);

        setWindowController({
          houseID: houseID,
          agID: agID,
          akID: akID,
          layerID: layerID,
          layerSubID: rev_data[3],
          oppstate: oppstate,
          openRate: openRate,
        });

        console.log("houseID : ", houseID);
        console.log("agID : ", agID);
        console.log("akID : ", akID);
        console.log("layerID : ", layerID);
        console.log("layerSubID : ", layerSubID);
        console.log("oppstate : ", oppstate);
        console.log("openRate : ", openRate);
        break;
      case CH_OPPFIT:
        hc_agid = utils.pad(rev_data[1].toString(2), 8);
        houseID = parseInt(hc_agid.substring(0, 4), 2);
        agID = parseInt(hc_agid.substring(4, 8), 2);

        akid_layerid = utils.pad(rev_data[2].toString(2), 8);
        akID = parseInt(akid_layerid.substring(0, 4), 2);
        layerID = parseInt(akid_layerid.substring(4, 8), 2);

        layerSubID = rev_data[3];

        oppstate = 1; // 1이나 2나 상관 없이 형식 맞추기 용
        openRate = parseInt(rev_data[5]);
        setWindowController({
          houseID: houseID,
          agID: agID,
          akID: akID,
          layerID: layerID,
          layerSubID: rev_data[3],
          oppstate: oppstate,
          openRate: openRate,
        });

        console.log("houseID : ", houseID);
        console.log("agID : ", agID);
        console.log("akID : ", akID);
        console.log("layerID : ", layerID);
        console.log("layerSubID : ", layerSubID);
        console.log("oppstate : ", oppstate);
        console.log("openRate : ", openRate);
        break;
      case CH_OPENRATE:
        let houseCnt = rev_data[1];
        let pos = 2;
        for (let idx = 0; idx < houseCnt; idx++) {
          let houseDataSize = rev_data[pos++];

          let houseData = rev_data.slice(pos, pos + houseDataSize);
          let hc_agCnt = utils.pad(houseData[0].toString(2), 8);
          let hc = parseInt(hc_agCnt.substring(0, 4), 2);
          house.changeOpenrate(houseData);
          pos = pos + houseDataSize;
        }

        setWindowController((cur) => {
          let newState = { ...cur };
          newState.openRate = parseInt(rev_data[11]);
          return newState;
        });

        console.log("houseID : ", houseID);
        console.log("agID : ", agID);
        console.log("akID : ", akID);
        console.log("layerID : ", layerID);
        console.log("layerSubID : ", layerSubID);
        console.log("oppstate : ", oppstate);
        console.log("openRate : ", openRate);
        break;
      case C_ISLOCAL:
        houseID = rev_data[1];
        isLocal = rev_data[2];
        // setSocketIsLocal(rev_data[2]);  // 연동
        // 1: 다른 컴퓨터가 로컬 제어 선택 => 즉 소켓 응답으로 1 오면 이 컴퓨터는 원격 제어 가능!
        // 0: 다른 컴퓨터가 원격 제어 선택 => 즉 소켓 응답으로 0 오면 이 컴퓨터는 제어권 상실
        break;
      case CH_AUTOMANUAL:
        houseID = rev_data[1];
        isAuto = rev_data[3];
        setIsAi(isAuto); // 1: ai 3:자동 4:수동
        console.log("houseID", houseID);
        console.log("isAuto:", isAuto);
        break;
      case AI_PRIORITY:
        houseID = rev_data[1];
        let aiDone = rev_data[2];
        console.log("hodsuseID", houseID);
        console.log("aiDone", aiDone);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setSocketData((cur) => {
      let newState = { ...cur };
      if (data.house) {
        newState.houseSensors = data?.house[0]?.sensorNodes;
      }
      newState.weatherstation = data?.weatherstation?.sensors;
      newState.sunrise = data?.sunrise;
      newState.sunset = data?.sunset;
      return newState;
    });
  }, [data]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        socketData,
        onOffController,
        windowController,
        socketIsLocal,
        isAi,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
