import { instance, tnsInstance } from "./Axios";
import { getFarmConfig } from "../utils/getFarmConfig";

// Tenefarm에서 가져오는 것
export const getFarmInitial = async (farm_id) => {
  const farmConfig = getFarmConfig(farm_id);
  if (!farmConfig) {
    throw new Error(`No configuration found for farm_id: ${farm_id}`);
  }

  const response = await tnsInstance.get("ict/getfarminfo", {
    params: {
      usr_id: farmConfig.id,
    },
  });
  return response.data.data[0].control[0];
};

// db에 저장된 farm 선택 정보
export const getFarm = async (api_key, user_id) => {
  const response = await instance.post("dbserver/goatfarm/getfarm", {
    api_key: api_key,
    user_id: user_id,
  });
  return response.data;
};

// db에 저장된 farm 선택 정보
export const setFarm = async (
  api_key,
  user_id,
  farm_id,
  pl_code,
  device,
  pl_model
) => {
  const response = await instance.post("dbserver/goatfarm/setfarm", {
    api_key: api_key,
    user_id: user_id,
    farm_id: farm_id,
    pl_code: pl_code,
    device: device,
    pl_model: pl_model,
  });
  return response.data;
};

export const kakaoLogin = async (code) => {
  console.log("code", code);
  const response = await instance.post("users/social/kakao", { code: code });

  return response.data;
};
