import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { createJournal } from "../../api/JournalApi";
import { insertAlarms } from "../../api/NotificationApi";
import useStore from "../../store/store";
import formatDate from "../../utils/formatDate";
import { getAlarmDates, LOMAINE_ASAN } from "../Notification/Alarms";
import { useTranslation } from "react-i18next";

const Container = styled.form`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;

  .first-row {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    align-items: center;
    border-bottom: 0.5px solid black;
    height: 3rem;
    div {
      grid-template-rows: 1fr;
      padding-bottom: 0;
    }
  }
  .second-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding-top: 0.5rem;
  }
  .first-col {
    font-size: 1.125rem;
    color: #969696;
    border-right: 0.5px solid black;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    div {
      height: 3rem;
      display: flex;
      align-items: center;
    }
  }
  .second-col {
    padding-left: 1rem;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    align-items: center;
    div {
      height: 3rem;
      display: flex;
    }
    input {
      height: 3rem;
      display: flex;
      background: none;
    }
  }
  .third-col {
    display: flex;
    justify-content: end;
    input {
      margin-right: 1rem;
    }
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  a {
    text-decoration: none;
    color: #4d4d4d;
  }
`;
const Title = styled.div`
  font-size: 1.125rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #5f9137;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
`;

const Input = styled.input`
  border: none;
  ::placeholder {
    font-size: 1rem;
    color: #969696;
    white-space: normal;
    display: block;
    overflow-wrap: break-word;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: #ffffff;
  }
`;

const LOMAINE_PL_CODE = "08001";
const TOMATO_PL_CODE = "02001";

const JournalForm = () => {
  const { t } = useTranslation("monitoring");

  const { api_key, farm_id, pl_code } = useStore();
  const [date, setDate] = useState(new Date().getTime());
  const [pl_id_cde, setPlIdCode] = useState("");
  const [pl_id_clicked, setPlIdClicked] = useState(false);
  const [fields, setFields] = useState({});
  const [isInputClicked, setIsInputClicked] = useState([]);
  const [transplanting, setTransplanting] = useState(false);

  const navigate = useNavigate();

  const LOMAINE_COLUMN_LIST = [
    { dbIdx: "pllnLt", unit: "cm", tIdx: "plantHeight" },
    { dbIdx: "lefCunt", unit: t("journals.unit.pcs"), tIdx: "leafCount" },
    { dbIdx: "lefLt", unit: "cm", tIdx: "leafLength" },
    { dbIdx: "lefBt", unit: "cm", tIdx: "leafWidth" },
    { dbIdx: "petLt", unit: "cm", tIdx: "petioleLength" },
    {
      dbIdx: "hvstGrupp",
      unit: t("journals.unit.point"),
      tIdx: "harvestGroup",
    },
    { dbIdx: "outtrn", unit: "g", tIdx: "harvestWeight" },
    { dbIdx: "hvstDayCo", unit: "g", tIdx: "dailyHarvestWeight" },
  ];

  const TOMATO_COLUMN_LIST = [
    { dbIdx: "pllnLt", unit: "cm", tIdx: "plantHeight" },
    { dbIdx: "grwtLt", unit: "cm", tIdx: "growthLength" },
    { dbIdx: "lefCunt", unit: t("journals.unit.pcs"), tIdx: "leafCount" },
    { dbIdx: "lefLt", unit: "cm", tIdx: "leafLength" },
    { dbIdx: "lefBt", unit: "cm", tIdx: "leafWidth" },
    { dbIdx: "stemThck", unit: "cm", tIdx: "stemThick" },
    { dbIdx: "fcluHg", unit: "cm", tIdx: "inflorescenceHeight" },
    { dbIdx: "flanGrupp", unit: t("journals.unit.point"), tIdx: "flowerGroup" },
    {
      dbIdx: "frtstGrupp",
      unit: t("journals.unit.point"),
      tIdx: "fruitingHerdGroup",
    },
    {
      dbIdx: "hvstGrupp",
      unit: t("journals.unit.point"),
      tIdx: "harvestGroup",
    },
    { dbIdx: "frstCo", unit: t("journals.unit.pcs"), tIdx: "fruitCount" },
    { dbIdx: "hvstCo", unit: t("journals.unit.pcs"), tIdx: "harvestCount" },
    { dbIdx: "outtrn", unit: "g", tIdx: "harvestWeight" },
  ];

  const handleCheckbox = (e) => {
    const isChecked = e.target.checked;
    setTransplanting(isChecked);
    if (isChecked) {
      setFields(
        (pl_code === LOMAINE_PL_CODE
          ? LOMAINE_COLUMN_LIST
          : TOMATO_COLUMN_LIST
        ).reduce((acc, col) => {
          acc[col.dbIdx] = 0;
          return acc;
        }, {})
      );
      setPlIdCode(0);
    } else {
      setFields(
        (pl_code === LOMAINE_PL_CODE
          ? LOMAINE_COLUMN_LIST
          : TOMATO_COLUMN_LIST
        ).reduce((acc, col) => {
          acc[col.dbIdx] = "";
          return acc;
        }, {})
      );
      setPlIdCode("");
    }
  };
  const handleInputClicked = (index) => {
    setIsInputClicked((prev) =>
      prev.map((input, idx) => (idx === index ? !input : input))
    );
  };
  const handlePIClicked = () => {
    setPlIdClicked((prev) => !prev);
  };
  const handlePIChange = (e) => {
    setPlIdCode(e.target.value);
  };
  const handleChange = (menu, e) => {
    setFields({ ...fields, [menu]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDate(new Date().getTime());

    const areFieldsFilled = Object.values(fields).every(
      (value) => value !== "" && value !== null && value !== undefined
    );

    if (!areFieldsFilled) {
      alert(t("journals.create.notification"));
      return;
    }

    createJournal(
      api_key,
      farm_id,
      pl_id_cde,
      pl_code,
      fields,
      formatDate(date, "YYYY-MM-DD HH:MM:SS"),
      transplanting
    )
      .then((res) => {
        // 정식일인 경우 설정
        if (transplanting) {
          // 알림 데이터 생성
          const data = {
            culvDate: formatDate(date, "YYYY-MM-DD HH:MM:SS"),
            plCode: pl_code, // 아산 로메인
          };
          const alarmDates = getAlarmDates(
            formatDate(date, "YYYY-MM-DD HH:MM:SS"),
            pl_code
          );
          // 알림 API 호출
          insertAlarms(api_key, farm_id, data, alarmDates)
            .then((res) => console.log("Alarm data inserted:", res))
            .catch((error) =>
              console.error("Error inserting alarm data:", error)
            );
        }

        // 디테일 페이지로 이동
        navigate(`/monitoring/journals/${res.journal_seq}`, {
          state: { journal_seq: res.journal_seq },
        });
      })
      .catch((error) => console.error("Error creating journal:", error));
  };

  const handleClose = () => {
    navigate(`/monitoring`);
  };

  return (
    <Container action="submit">
      <TitleWrapper>
        <Title>{t("journals.create.title")}</Title>
        <ButtonWrapper>
          <Button onClick={handleSubmit}>{t("general.save")}</Button>
          <Button onClick={handleClose}>{t("general.close")}</Button>
        </ButtonWrapper>
      </TitleWrapper>
      <div className="first-row">
        <div className="first-col">{t("journals.item.plantId")}</div>
        <div className="second-col">
          <Input
            type="text"
            onFocus={() => handlePIClicked()}
            onBlur={() => handlePIClicked()}
            placeholder={t("journals.placeholder.plantId")}
            value={pl_id_cde}
            onChange={(e) => handlePIChange(e)}
          />
        </div>
        <div className="third-col">
          <label htmlFor="checkbox">{t("journals.transplanting.date")}</label>
          <Input
            type="checkbox"
            id="checkbox"
            checked={transplanting}
            onChange={handleCheckbox}
          />
          <div>
            {t("general.date")}: {formatDate(date, "YYYY-MM-DD")}
          </div>
        </div>
      </div>
      <div className="second-row">
        <div className="first-col" rows={pl_code === LOMAINE_PL_CODE ? 8 : 13}>
          {pl_code === LOMAINE_PL_CODE
            ? LOMAINE_COLUMN_LIST.map((col) => (
                <div key={col.tIdx}>{t(`journals.item.${col.tIdx}`)}</div>
              ))
            : pl_code === TOMATO_PL_CODE
            ? TOMATO_COLUMN_LIST.map((col) => (
                <div key={col.tIdx}>{t(`journals.item.${col.tIdx}`)}</div>
              ))
            : null}
        </div>
        <div className="second-col" rows={pl_code === LOMAINE_PL_CODE ? 8 : 13}>
          {pl_code === LOMAINE_PL_CODE
            ? LOMAINE_COLUMN_LIST.map((col, idx) => (
                <Input
                  key={col.dbIdx}
                  type="text"
                  onFocus={() => handleInputClicked(idx)}
                  onBlur={() => handleInputClicked(idx)}
                  placeholder={
                    isInputClicked[idx]
                      ? ""
                      : t(`journals.placeholder.${col.tIdx}`)
                  }
                  value={fields[col.dbIdx]}
                  onChange={(e) => handleChange(col.dbIdx, e)}
                />
              ))
            : pl_code === TOMATO_PL_CODE
            ? TOMATO_COLUMN_LIST.map((col, idx) => (
                <Input
                  key={col.dbIdx}
                  type="text"
                  onFocus={() => handleInputClicked(idx)}
                  onBlur={() => handleInputClicked(idx)}
                  placeholder={
                    isInputClicked[idx]
                      ? ""
                      : t(`journals.placeholder.${col.tIdx}`)
                  }
                  value={fields[col.dbIdx]}
                  onChange={(e) => handleChange(col.dbIdx, e)}
                />
              ))
            : null}
        </div>
      </div>
    </Container>
  );
};

export default JournalForm;
