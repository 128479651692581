import React from "react";
import styled from "styled-components";

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
// `;
// const Title = styled.div`
//   font-size: 1.125rem;
// `;
// const ContentWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
// `;
const Subtitle = styled.div`
  font-size: 0.875rem;
  justify-items: center;
  padding-bottom: 0.5rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
`;
const Button = styled.button`
  color: #4d4d4d;
  background-color: #fff;
  height: 1.75rem;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
  border: 0.5px solid #e2e4e5;
  border-radius: 3px;
  .selected {
    border: none;
    background-color: #5f9137;
    color: #fff;
  }
`;
const AutoControllerForm = () => {
  return (
    <>
      <Subtitle>구분</Subtitle>
      <ButtonWrapper>
        <Button>환기</Button>
        <Button>보온</Button>
      </ButtonWrapper>
      <Subtitle>작동</Subtitle>
      <ButtonWrapper>
        <Button>일출 전</Button>
        <Button>일출</Button>
        <Button>일출 후</Button>
        <Button>일몰</Button>
        <Button>일몰 후</Button>
        <Button>지정</Button>
      </ButtonWrapper>
      <Subtitle>작동 시각</Subtitle>
      <ButtonWrapper>
        <Button>00:00</Button>-<Button>00:00</Button>
      </ButtonWrapper>
      <Subtitle>온도</Subtitle>
      <ButtonWrapper>
        <Button>00.00</Button>
      </ButtonWrapper>
      <Subtitle>습도</Subtitle>
      <ButtonWrapper>
        <Button>00%</Button>
      </ButtonWrapper>
    </>
  );
};

export default AutoControllerForm;
