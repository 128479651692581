import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { editJournal, getJournalDetail } from "../../api/JournalApi";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useStore from "../../store/store";

const Container = styled.form`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;

  .first-row {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    align-items: center;
    border-bottom: 0.5px solid black;
    height: 3rem;
    div {
      grid-template-rows: 1fr;
      padding-bottom: 0;
    }
  }
  .second-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding-top: 0.5rem;
  }
  .first-col {
    font-size: 1.125rem;
    color: #969696;
    border-right: 0.5px solid black;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    div {
      height: 3rem;
      display: flex;
      align-items: center;
    }
  }
  .second-col {
    padding-left: 1rem;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    align-items: center;
    div {
      height: 3rem;
      display: flex;
    }
    input {
      height: 3rem;
      display: flex;
      background: none;
    }
  }
  .third-col {
    display: flex;
    justify-content: end;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  a {
    text-decoration: none;
    color: #4d4d4d;
  }
`;
const Title = styled.div`
  font-size: 1.125rem;
  color: #969696;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #5f9137;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
`;

const Input = styled.input`
  border: none;
  ::placeholder {
    font-size: 1rem;
    color: #969696;
  }
  &:focus {
    outline: none;
  }
`;

const LOMAINE_PL_CODE = "08001";
const TOMATO_PL_CODE = "02001";

const Journal_Edit = () => {
  const { t } = useTranslation("monitoring");
  const navigate = useNavigate();
  const location = useLocation();
  const journal_seq = location.state.journal_seq; // parameter로 받아옴

  const { api_key, farm_id, pl_code } = useStore();
  const [pl_id_cde, setPlIdCode] = useState("");
  const [pl_id_clicked, setPlIdClicked] = useState(false);
  const [fields, setFields] = useState({});
  const [isInputClicked, setIsInputClicked] = useState([]);

  const LOMAINE_COLUMN_LIST = [
    { dbIdx: "pllnLt", unit: "cm", tIdx: "plantHeight" },
    { dbIdx: "lefCunt", unit: t("journals.unit.pcs"), tIdx: "leafCount" },
    { dbIdx: "lefLt", unit: "cm", tIdx: "leafLength" },
    { dbIdx: "lefBt", unit: "cm", tIdx: "leafWidth" },
    { dbIdx: "petLt", unit: "cm", tIdx: "petioleLength" },
    {
      dbIdx: "hvstGrupp",
      unit: t("journals.unit.point"),
      tIdx: "harvestGroup",
    },
    { dbIdx: "outtrn", unit: "g", tIdx: "harvestWeight" },
    { dbIdx: "hvstDayCo", unit: "g", tIdx: "dailyHarvestWeight" },
  ];
  const TOMATO_COLUMN_LIST = [
    { dbIdx: "pllnLt", unit: "cm", tIdx: "plantHeight" },
    { dbIdx: "grwtLt", unit: "cm", tIdx: "growthLength" },
    { dbIdx: "lefCunt", unit: t("journals.unit.pcs"), tIdx: "leafCount" },
    { dbIdx: "lefLt", unit: "cm", tIdx: "leafLength" },
    { dbIdx: "lefBt", unit: "cm", tIdx: "leafWidth" },
    { dbIdx: "stemThck", unit: "cm", tIdx: "stemThick" },
    { dbIdx: "fcluHg", unit: "cm", tIdx: "inflorescenceHeight" },
    { dbIdx: "flanGrupp", unit: t("journals.unit.point"), tIdx: "flowerGroup" },
    {
      dbIdx: "frtstGrupp",
      unit: t("journals.unit.point"),
      tIdx: "fruitingHerdGroup",
    },
    {
      dbIdx: "hvstGrupp",
      unit: t("journals.unit.point"),
      tIdx: "harvestGroup",
    },
    { dbIdx: "frstCo", unit: t("journals.unit.pcs"), tIdx: "fruitCount" },
    { dbIdx: "hvstCo", unit: t("journals.unit.pcs"), tIdx: "harvestCount" },
    { dbIdx: "outtrn", unit: "g", tIdx: "harvestWeight" },
  ];

  const handleInputClicked = (index) => {
    const nextInputClicked = isInputClicked.map((input, idx) => {
      if (idx === index) {
        // 클릭된 input의 상태 변경
        return !input;
      } else {
        // 변경되지 않은 나머지를 반환
        return input;
      }
    });
    setIsInputClicked(nextInputClicked);
  };
  const handlePIClicked = () => {
    setPlIdClicked((prev) => !prev);
  };
  const handlePIChange = (e) => {
    setPlIdCode(e.target.value);
  };

  const handleChange = (menu, e) => {
    setFields({ ...fields, [menu]: +e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editJournal(
      api_key,
      farm_id,
      pl_id_cde,
      pl_code,
      journal_seq,
      fields,
      fields.date
    ).then(() =>
      navigate(`/monitoring/journals/${journal_seq}`, {
        state: { journal_seq: journal_seq },
      })
    );
  };

  const handleClose = () => {
    navigate(`/monitoring`);
  };

  useEffect(() => {
    if (farm_id) {
      getJournalDetail(api_key, farm_id, pl_code, journal_seq).then((res) => {
        setFields(res);
        setPlIdCode(res.pl_id_cde);
        // initialState 설정
        if (pl_code === LOMAINE_PL_CODE) {
          setIsInputClicked([
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ]);
        } else if (pl_code === TOMATO_PL_CODE) {
          setIsInputClicked([
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ]);
        }
      });
    }
  }, [farm_id]);

  return (
    <Container action="submit">
      <TitleWrapper>
        <Title>
          {t("general.date")} : {fields.date?.slice(0, 10)}
        </Title>
        <ButtonWrapper>
          <Button onClick={handleSubmit}>{t("general.save")}</Button>
          <Button onClick={handleClose}>{t("general.close")}</Button>
        </ButtonWrapper>
      </TitleWrapper>
      <div className="first-row">
        <div className="first-col">{t("journals.item.plantId")}</div>
        <div className="second-col">
          <Input
            type="text"
            onFocus={() => handlePIClicked()}
            onBlur={() => handlePIClicked()}
            placeholder={pl_id_clicked ? "" : pl_id_cde}
            value={pl_id_cde || ""}
            onChange={(e) => handlePIChange(e)}
          />
        </div>
        <div className="third-col">
          {t("general.date")}: {fields.date}
        </div>
      </div>
      <div className="second-row">
        <div className="first-col">
          {pl_code === LOMAINE_PL_CODE
            ? LOMAINE_COLUMN_LIST.map((col) => (
                <div key={col}>{t(`journals.item.${col.tIdx}`)}</div>
              ))
            : pl_code === TOMATO_PL_CODE
            ? TOMATO_COLUMN_LIST.map((col) => (
                <div key={col}>{t(`journals.item.${col.tIdx}`)}</div>
              ))
            : null}
        </div>
        <div className="second-col">
          {pl_code === LOMAINE_PL_CODE
            ? LOMAINE_COLUMN_LIST.map((col, idx) => (
                <Input
                  key={col.dbIdx}
                  type="text"
                  onFocus={() => handleInputClicked(idx)}
                  onBlur={() => handleInputClicked(idx)}
                  placeholder={
                    isInputClicked[idx]
                      ? ""
                      : `${fields[col.dbIdx] ? fields[col.dbIdx] : ""}`
                  }
                  value={fields[col.dbIdx] || ""}
                  onChange={(e) => handleChange(col.dbIdx, e)}
                />
              ))
            : pl_code === TOMATO_PL_CODE
            ? TOMATO_COLUMN_LIST.map((col, idx) => (
                <Input
                  key={col.dbIdx}
                  type="text"
                  onFocus={() => handleInputClicked(idx)}
                  onBlur={() => handleInputClicked(idx)}
                  placeholder={
                    isInputClicked[idx]
                      ? ""
                      : `${fields[col.dbIdx] ? fields[col.dbIdx] : ""}`
                  }
                  value={fields[col.dbIdx] || ""}
                  onChange={(e) => handleChange(col.dbIdx, e)}
                />
              ))
            : null}
        </div>
      </div>
    </Container>
  );
};

export default Journal_Edit;
