import React from "react";
import styled from "styled-components";
import GF_Wordmark_Large from "../../assets/img/GF_Wordmark_Large.svg";
import GF_Logo from "../../assets/img/GF_Logo.svg";
import Login_Google from "../../assets/img/Login_Google.svg";
import Login_Kakao from "../../assets/img/Login_Kakao.svg";
import Login_Apple from "../../assets/img/Login_Apple.svg";
import useStore from "../../store/store";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const LeftPane = styled.div`
  background-color: #5f9137;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.div`
  text-align: center;
  color: white;

  h1 {
    font-size: 3rem;
    margin: 0;
  }

  p {
    font-size: 0.9rem;
    margin-top: 10px;
  }
`;

const RightPane = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const FormContainer = styled.div`
  width: 36%;
  max-width: 400px;
`;

const LogoIcon = styled.div`
  text-align: center;
`;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 800;
  color: #5f9137;
  text-align: center;
  margin-bottom: 0.3rem;
`;

const Subtitle = styled.div`
  font-size: 0.75rem;
  color: #2e3829;
  text-align: center;
  margin-bottom: 1rem;
`;

const Label = styled.div`
  font-size: 0.875rem;
  color: #4d4d4d;
  margin-bottom: 0.4rem;
`;

const Input = styled.input`
  width: 92%;
  padding: 0.4rem 0.6rem;
  margin-bottom: 0.875rem;
  border-radius: 5px;
  border: 1px solid #5f9137;
  font-size: 0.875rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.4rem 0.6rem;
  background-color: #5f9137;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.875rem;
  cursor: pointer;
  margin-bottom: 20px;
`;

const Divider = styled.div`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0.875rem 0;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #dddddd;
  }

  &::before {
    margin-right: 0.5em;
  }

  &::after {
    margin-left: 0.5em;
  }
`;

const SocialButton = styled(Button)`
  background-color: ${(props) => props.bgColor};
  border: ${(props) => props.border};
  color: ${(props) => props.color || "#4d4d4d"};
  padding: 0 0.6rem;
  height: 1.875rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  a {
    color: #5f9137;
    font-size: 0.938rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Login = () => {
  const { setIsLoggedIn } = useStore();
  const navigate = useNavigate();
  const handleKakaoLogin = () => {
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
    window.location.href = KAKAO_AUTH_URL;
  };

  const handleClickLogin = () => {
    setIsLoggedIn(true);
    navigate("/dashboard");
  };

  return (
    <Container>
      <LeftPane>
        <Logo>
          <img src={GF_Wordmark_Large} />
        </Logo>
      </LeftPane>
      <RightPane>
        <FormContainer>
          <LogoIcon>
            <img src={GF_Logo} />
          </LogoIcon>
          <Title>Create an account</Title>
          <Subtitle>Provide your email and choose a password</Subtitle>
          <Label>E-mail*</Label>
          <Input type="email" placeholder="E-mail" />
          <Label>Password*</Label>
          <Input type="password" placeholder="Choose a password" />
          <Divider>OR</Divider>
          <SocialButton bgColor="#ffffff" border="0.4px solid #C0C0C0">
            <img src={Login_Google} alt="Google" /> Log in with Google
          </SocialButton>
          <SocialButton onClick={handleKakaoLogin} bgColor="#fee500">
            <img src={Login_Kakao} alt="Kakao" /> Log in with Kakao
          </SocialButton>
          <SocialButton bgColor="#000000" color="#fff">
            <img src={Login_Apple} alt="Apple" /> Log in with Apple ID
          </SocialButton>
          <Button onClick={handleClickLogin}>Continue</Button>
          <Footer>
            <a href="/contact">Contact</a>
            <a href="/login">Log In</a>
          </Footer>
        </FormContainer>
      </RightPane>
    </Container>
  );
};

export default Login;
