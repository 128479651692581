import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SensorSkeleton from "./SensorSkeleton";
import Title from "../../components/layout/Title";
import Radar from "../../assets/img/Menu_Radar.svg";
import SensorItem from "./SensorItem";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 14rem;
  background-color: #5f913713;
  border-radius: 1.6rem;
  padding: 1.25rem;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;
const GuideItemWrapper = styled.div`
  display: flex;
`;
const GuideItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: #969696;
  margin-left: 0.5rem;
`;
const Circle = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  margin-right: 0.5rem;
  background-color: ${(props) => props.bc};
`;
const SensorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .sensor {
    margin: 0.5rem 0;
  }
`;
const SensorItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

const Sensor = ({ structure, houseSensors }) => {
  const [completeData, setCompleteData] = useState([]);
  const { t } = useTranslation("dashboard", { keyPrefix: "sensor" });

  const GUIDE = useMemo(
    () => [
      { id: 0, name: `${t("guide.safe")}`, color: "#395EF6" },
      { id: 1, name: `${t("guide.warning")}`, color: "#E8C600" },
    ],
    [t]
  );

  useEffect(() => {
    if (structure && houseSensors) {
      const updatedStructure = structure.map((item, index) => {
        const sensorNode = houseSensors[index]?.sensors;
        const updatedData = item.data.map((sensor) => {
          const sensorData = sensorNode?.find((s) => s.sensorID === sensor.key);

          if (sensorData) {
            return { ...sensor, value: sensorData.value };
          }
          return sensor;
        });

        return { ...item, data: updatedData };
      });

      setCompleteData(updatedStructure);
    }
  }, [structure, houseSensors]); // Recalculate when structure or houseSensors change

  if (!houseSensors) {
    return <SensorSkeleton />;
  }

  return (
    <>
      {completeData.length > 0 && (
        <Container>
          <Header>
            <Title src={Radar} title={t("title")} />
            <GuideItemWrapper>
              {GUIDE.map((val) => (
                <GuideItem key={val.id}>
                  <Circle bc={val.color} />
                  <div>{val.name}</div>
                </GuideItem>
              ))}
            </GuideItemWrapper>
          </Header>
          <SensorWrapper>
            {completeData.map((item) => (
              <div key={item.snnam}>
                {item.data.length > 0 && (
                  <div>
                    <div className="sensor">{t(`category.${item.snnam}`)}</div>
                    <SensorItemWrapper>
                      {item.data.map((sensor) => (
                        <SensorItem key={sensor.key} {...sensor} />
                      ))}
                    </SensorItemWrapper>
                  </div>
                )}
              </div>
            ))}
          </SensorWrapper>
        </Container>
      )}
    </>
  );
};

export default Sensor;
