import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  padding: ${(props) => props.padding || "0 0 0.5rem 0"};
  align-items: center;
  img {
    height: 1.125rem;
    padding-right: 0.5rem;
  }
  div {
    font-size: 1.125rem;
  }
`;
const Title = ({ src, title, padding }) => {
  return (
    <Container padding={padding}>
      <img src={src} />
      <div className="title">{title}</div>
    </Container>
  );
};

export default Title;
