import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TruncateText from "../../../utils/truncateText";
import styled from "styled-components";
import Settings_Global from "../../../assets/img/Settings_Global.svg";

const Container = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  border: 1px solid;
  width: 9.18rem;
  height: 2.68rem;
  color: #5f9137;
  border: 1px solid #5f9137;
  font-size: 1.25rem;
  margin-right: 1rem;
`;
const Icon = styled.img`
  width: 1.5rem;
`;
const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  svg {
    width: 16px;
  }
`;

const ListWrapper = styled.div`
  position: absolute;
  width: 80%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  color: #5f9137;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
  border-radius: 0 0 4px 4px;
  margin-top: 2.7rem;
  padding: 0.8rem;
  max-height: 15rem;
`;

const List = styled.div`
  max-height: 10rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 7px solid #ffffff;
    background: #cfdec3;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    border: 7px solid #ffffff;
    background: #cfdec34d;
  }
`;
const ListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.active,
  &:hover {
    background-color: #f1f5ed;
  }
`;
const ListItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0.7rem;
  border-radius: 3px;
  font-size: 1rem;
  color: #4d4d4d;
`;

const LanguageDropdown = () => {
  const { i18n } = useTranslation();

  const [options, setOptions] = useState([
    { title: "ENGLISH", code: "en" },
    { title: "한국어", code: "ko" },
  ]);

  const [language, setLanguage] = useState("");

  const [dropdown, setDropdown] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // 변경
  const handleClick = (title, code) => {
    setLanguage(title);
    setDropdown(!dropdown);
    changeLanguage(code);
  };

  const dropdownRef = useRef();
  const itemRef = useRef();

  // 바깥 영역 클릭시
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    if (i18n.language) {
      setLanguage(options.find((opt) => opt.code === i18n.language)?.title);
    }
  }, [i18n.language]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (dropdown) {
      const activeItem = itemRef.current.querySelector(".active");
      itemRef.current.scrollTop = activeItem?.offsetTop - 72;
    }
  }, [dropdown]);

  return (
    <>
      <Container ref={dropdownRef}>
        <DropdownWrapper onClick={() => setDropdown(!dropdown)}>
          <Icon src={Settings_Global} alt="langauge" />
          <TruncateText text={language} maxLength={7} />
        </DropdownWrapper>
        {dropdown && (
          <ListWrapper>
            <List ref={itemRef}>
              {options.length !== 0 ? (
                options.map((opt) => (
                  <ListItemWrapper key={opt.code}>
                    <ListItem
                      onClick={() => {
                        handleClick(opt.title, opt.code);
                      }}
                      className={opt.title === language && "active"}
                    >
                      {opt.title}
                    </ListItem>
                  </ListItemWrapper>
                ))
              ) : (
                <></>
              )}
            </List>
          </ListWrapper>
        )}
      </Container>
    </>
  );
};

export default LanguageDropdown;
