import { useTranslation } from "react-i18next";

const weatherDictionary = () => {
  const { t } = useTranslation("dashboard", {
    keyPrefix: "extWeather.weatherDictionary",
  });
  const winddirection = {
    0: t("windDirection.N"),
    1: t("windDirection.NE"),
    2: t("windDirection.E"),
    3: t("windDirection.SE"),
    4: t("windDirection.S"),
    5: t("windDirection.SW"),
    6: t("windDirection.W"),
    7: t("windDirection.NW"),
  };

  // let rainStep = {
  //   0: "--",
  //   1: "이슬비",
  //   2: "가랑비",
  //   3: "비",
  //   4: "장대비",
  //   5: "폭우",
  // };

  const skyStep = {
    1: t("skyStep.맑음"),
    3: t("skyStep.구름많음"),
    4: t("skyStep.흐림"),
  };

  const rainStep = {
    0: t("rainStep.맑음"),
    1: t("rainStep.비"),
  };

  return { winddirection, skyStep, rainStep };
};

export default weatherDictionary;
