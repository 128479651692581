import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import useStore from "../../store/store";
import formatDate from "../../utils/formatDate";
import { getAlarms, readAlarms } from "../../api/NotificationApi";

import ExclamationGreen from "../../assets/img/Notification_Exclamation_Green.svg";
import ExclamationRed from "../../assets/img/Notification_Exclamation_Red.svg";
import ExclamationGray from "../../assets/img/Notification_Exclamation_Gray.svg";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 94%;
  background-color: #ffffff;
  margin: 4.8rem 1rem 1rem 1rem;
  padding: 1rem;
`;
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2.5rem;
`;

const NoteListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  margin-bottom: 0.5rem;
  &.green {
    background-color: #cfdec3;
  }
  &.yellow {
    background-color: #ffee8b66;
  }
  &.gray {
    background-color: #f5f5f5;
  }
  .date {
    color: #555555b3;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: start;
`;

const Icon = styled.img`
  width: ${(props) => props.width || "1.5rem"};
  padding-right: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  .type {
    display: flex;
    border-radius: 20px;
    font-size: 1.5rem;
  }
  .content {
    font-size: 1.125rem;
  }
`;

const EmptyMessageWrapper = styled.div`
  background-color: #f7f9f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  .title {
    font-size: 1.25rem;
    padding: 0.5rem 0;
  }
`;

const Notification = () => {
  const { t } = useTranslation("notification");

  const { api_key, farm_id } = useStore();
  const [alarms, setAlarms] = useState([]);
  const [date, setDate] = useState(new Date().getTime());
  const navigate = useNavigate();

  const ALARMS = useMemo(
    () => ({
      A0001: {
        title: t("title.inspection"),
        description: t("description.inspection"),
      },
      A0002: {
        title: t("title.harvest"),
        description: t("description.harvest"),
      },
    }),
    []
  );

  const handleClickAlarm = ({ date, alCode, readStatus }) => {
    if (!readStatus) {
      // 안읽은 것만 읽음 처리 가능
      if (alCode === "A0001") {
        readAlarms(
          api_key,
          farm_id,
          formatDate(date, "YYYY-MM-DD HH:MM:SS"),
          alCode,
          "Y"
        )
          .then(navigate("/monitoring/journals/create"))
          .catch((error) => console.error("Error reading alram:", error));
      } else {
        readAlarms(
          api_key,
          farm_id,
          formatDate(date, "YYYY-MM-DD HH:MM:SS"),
          alCode,
          "Y"
        )
          .then(navigate("/monitoring"))
          .catch((error) => console.error("Error reading alarm", error));
      }
    }
  };

  useEffect(() => {
    getAlarms(api_key, farm_id, date)
      .then((res) => setAlarms(res))
      .catch((error) => console.error("Error inserting alarm data:", error));
  }, []);
  return (
    <>
      <Container>
        {alarms.length !== 0 ? (
          <ColumnWrapper>
            {alarms &&
              alarms.map((val) => (
                <NoteListItem
                  key={val.date}
                  className={val.readStatus ? "gray" : "green"}
                  onClick={() => handleClickAlarm(val)}
                >
                  <FlexContainer>
                    {val.readStatus ? (
                      <Icon src={ExclamationGray} />
                    ) : (
                      <Icon src={ExclamationGreen} />
                    )}
                    <Wrapper>
                      <div className="type">{ALARMS[val.alCode].title}</div>
                      <div className="content">
                        {ALARMS[val.alCode].description}
                      </div>
                    </Wrapper>
                  </FlexContainer>
                  <div className="date">
                    {formatDate(val.date, "YYYY-MM-DD")}
                  </div>
                </NoteListItem>
              ))}
          </ColumnWrapper>
        ) : (
          <EmptyMessageWrapper>
            <Icon src={ExclamationGreen} width="3rem" />
            <div className="title">{t("notification")}</div>
          </EmptyMessageWrapper>
        )}
      </Container>
    </>
  );
};

export default Notification;
