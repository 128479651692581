import React, { useState, useEffect } from "react";
import styled from "styled-components";

import useStore from "../../store/store";
import { getCCTV } from "../../api/CCTVApi";
import Menu_Video from "../../assets/img/Menu_Video.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin: 3.9rem 1rem 0 1rem;
  padding: 1rem;
`;
const Header = styled.div`
  display: flex;
  padding: 0 0 1rem 0;
  align-items: center;
  img {
    width: 1.2rem;
    padding-right: 0.5rem;
  }
  div {
    font-size: 1.125rem;
  }
`;
const CamWrapper = styled.div`
  display: grid;
  grid-template-columns: 3.3fr 1fr;
  grid-column-gap: 1rem;
`;
const MainMonitor = styled.img`
  display: block;
  width: 100%;
  object-fit: contain;
  border-radius: 3px;
`;
const SubCamWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 1rem;
`;
const SubCam = styled.img`
  display: block;
  width: 100%;
  /* height: 13vh; */
  object-fit: cover;
  border-radius: 3px;
`;

const CCTV = () => {
  const { farm_id } = useStore();
  const [baseUrl, setBaseUrl] = useState("");
  const [camList, setCamList] = useState([]);
  const [subCamUrls, setSubCamUrls] = useState([]);
  const [mainCamUrl, setMainCamUrl] = useState(subCamUrls ? subCamUrls[0] : "");

  const handleSubCamClick = (clickedUrl, idx) => {
    // // swap
    // setSubCamUrls((prev) => {
    //   const newState = [...prev];
    //   newState[idx] = mainCamUrl;
    //   return newState.sort();
    // });
    setMainCamUrl(clickedUrl);
  };

  useEffect(() => {
    return () => {
      setSubCamUrls(null);
    };
  }, []);

  useEffect(() => {
    if (farm_id) {
      getCCTV(farm_id).then((res) => {
        setBaseUrl(`http://${res[0].cam_ip}:${res[0].http_port}/`);
        setCamList(res);
      });
    }
  }, [farm_id]);

  useEffect(() => {
    if (camList) {
      camList.forEach((cam) => {
        setSubCamUrls((prev) => [...prev, cam.cmd_v]);
      });
    }
  }, [camList]);

  useEffect(() => {
    if (subCamUrls) {
      setMainCamUrl(subCamUrls[0]);
    }
  }, [subCamUrls]);

  return (
    <Container>
      <Header>
        <img src={Menu_Video} alt="Menu Video" />
        <div>CCTV</div>
      </Header>
      <CamWrapper>
        {/* <MainMonitor
          src="http://ai-asan.iptime.org:9998/c01"
          alt="mainCamera"
        /> */}
        <MainMonitor src={baseUrl + mainCamUrl} alt="Main Camera" />
        <SubCamWrapper>
          {subCamUrls.map((url, idx) => (
            <SubCam
              key={idx}
              // src={`http://pwa.tenefarm.com:9998/${url}`}
              src={baseUrl + url}
              alt={`Sub Camera ${idx + 1}`}
              onClick={() => handleSubCamClick(url, idx)}
            />
          ))}
        </SubCamWrapper>
      </CamWrapper>
    </Container>
  );
};

export default CCTV;
