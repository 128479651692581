import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getModelList, deleteModel, getModel } from "../../api/ModelApi";
import useStore from "../../store/store";
import ModalConfirm from "./ModalConfirm";
import Dropdown_Down from "../../assets/img/Dropdown_Down.svg";
import Dropdown_Up from "../../assets/img/Dropdown_Up.svg";
import Delete from "../../assets/img/Delete.svg";

const Container = styled.div`
  display: flex;
`;

const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  svg {
    width: 16px;
  }
`;
const ListWrapper = styled.div`
  z-index: 1;
  position: absolute;
  width: 14%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  color: #5f9137;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
  border-radius: 0 0 4px 4px;
  margin-top: 2rem;
  padding: 0.8rem;
  max-height: 15rem;
`;

const List = styled.div`
  max-height: 10rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 7px solid #ffffff;
    background: #cfdec3;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    border: 7px solid #ffffff;
    background: #cfdec34d;
  }
`;
const ListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.active,
  &:hover {
    background-color: #f1f5ed;
  }
`;
const ListItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0.7rem;
  border-radius: 3px;
  font-size: 0.75rem;
  color: #4d4d4d;
`;
const CustomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 0.75rem;
  div {
    padding: 0.5rem 0.7rem;
  }
`;
const Icon = styled.img`
  width: 1rem;
  height: 1rem;
`;

const PL_MODEL = [
  { pl_model: 0, name: "A 로메인" },
  { pl_model: 1, name: "A 토마토" },
];

const Dropdown = () => {
  const { t } = useTranslation("dashboard", { keyPrefix: "aiController" });

  const { api_key, farm_id, pl_code, pl_model } = useStore();
  const [options, setOptions] = useState([]); // 목록
  const [model, setModel] = useState({ pl_model: -1, name: "" });
  const [dropdown, setDropdown] = useState(false);

  // 모델 선택하는 모달
  const [chosenVal, setChosenVal] = useState("");
  const [chosenId, setChosenId] = useState(-1);

  // 변경 모달 제어
  const [changeModal, setChangeModal] = useState(false);
  const handleChangeModal = (id, name) => {
    setChosenId(id);
    setChosenVal(name);
    setChangeModal(!changeModal);
  };
  // 실제 모델 변경
  const handleChangeModel = () => {
    // 원래는 여기에 모델별로 Rmse 불러오는 게 달라야함..
    handleChangeModal();
    setModel({
      pl_model: chosenId,
      name: chosenVal,
    });
    setDropdown(false);
  };

  // 삭제 버튼 (-)
  const [deleteButton, setDeleteButton] = useState(false);
  const onClickDeleteButon = () => {
    setDeleteButton(!deleteButton);
  };

  // 삭제 확인 모달
  const [deleteModal, setDeleteModal] = useState(false);
  const handleDeleteModal = (id, name) => {
    setChosenId(id);
    setChosenVal(name);
    setDeleteModal(!deleteModal);
  };

  // 학습 모델 삭제(진짜로)
  const handleDeleteModel = async () => {
    // 학습 모델 삭제 api
    await deleteModel(api_key, farm_id, pl_code);
    handleDeleteModal();
    setModel({ pl_model: options[0].pl_model, name: options[0].name });
    setDeleteButton(false);
    setDropdown(!dropdown);
  };

  const dropdownRef = useRef();
  const itemRef = useRef();

  // 바깥 영역 클릭시
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdown(false);
      setDeleteButton(false);
    }
  };

  useEffect(() => {
    if (farm_id) {
      getModelList(api_key, farm_id, pl_code)
        .then((res) => {
          setOptions(res);
        })
        .catch((error) => console.error("Error getting model list:", error));
    }
  }, [farm_id]);

  useEffect(() => {
    if (farm_id) {
      getModel(api_key, farm_id, pl_code)
        .then((res) => {
          setModel(PL_MODEL.find((m) => m.pl_model === res.pl_model));
        })
        .catch((error) => console.error("Error getting model list:", error));
    }
  }, [farm_id, pl_code, pl_model]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (dropdown) {
      const activeItem = itemRef.current.querySelector(".active");
      itemRef.current.scrollTop = activeItem?.offsetTop - 72;
    }
  }, [dropdown]);
  return (
    <Container ref={dropdownRef}>
      <DropdownWrapper>
        <div>{t("title")}</div>
        {!dropdown ? (
          <Icon src={Dropdown_Down} onClick={() => setDropdown(!dropdown)} />
        ) : (
          <Icon
            src={Dropdown_Up}
            onClick={() => {
              setDropdown(!dropdown);
              setDeleteButton(false);
            }}
          />
        )}
      </DropdownWrapper>
      {dropdown && (
        <ListWrapper>
          <List ref={itemRef}>
            {options.length !== 0 ? (
              options.map((opt) => (
                <ListItemWrapper key={opt.name}>
                  <>
                    <ListItem
                      onClick={() => {
                        handleChangeModal(opt.model_id, opt.name);
                      }}
                      className={opt.name === model && "active"}
                    >
                      {t(`model.${opt.name}`)}
                    </ListItem>
                    {deleteButton ? (
                      <Icon
                        src={Delete}
                        onClick={() => {
                          handleDeleteModal(opt.model_id, opt.name);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                </ListItemWrapper>
              ))
            ) : (
              <></>
            )}
          </List>
          <CustomWrapper>
            <div onClick={onClickDeleteButon}>{t("modal.deleteModel")}</div>
          </CustomWrapper>
        </ListWrapper>
      )}
      {changeModal ? (
        <ModalConfirm
          title={t("modal.changeModel")}
          close={() => handleChangeModal(chosenId, chosenVal)} // Modal 여닫기
          model_name={chosenVal}
          content={t("modal.notice")}
          model_id={chosenId}
          execute={() => handleChangeModal(chosenId, chosenVal)} // 변경 아님 닫기임.
          // execute={handleChangeModel} // Model 둘이 다름!! 주의
        />
      ) : null}
      {deleteModal ? (
        <ModalConfirm
          title={t("modal.deleteModel")}
          close={() => handleDeleteModal(chosenId, chosenVal)} // Modal 여닫기
          model_name={chosenVal}
          content={t("modal.deleteConfirmation", {
            chosenVal: t(`model.${chosenVal}`),
          })}
          model_id={chosenId}
          execute={() => handleDeleteModel(chosenId, chosenVal)} // Model 둘이 다름!! 주의
        />
      ) : null}
    </Container>
  );
};

export default Dropdown;
