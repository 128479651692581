import { CH_LAYER_SUB_STOP, CH_OPPFIT } from "./Consts";

const messageQueue = new Map();

const processQueue = (socket) => {
  const queue = messageQueue.get(socket);
  if (queue) {
    while (queue.length > 0 && socket.readyState === WebSocket.OPEN) {
      const buffer = queue.shift();
      socket.send(buffer);
    }
  }
};

const sendMessage = (socket, buffer) => {
  if (socket.readyState === WebSocket.OPEN) {
    socket.send(buffer);
  } else {
    if (!messageQueue.has(socket)) {
      messageQueue.set(socket, []);
      socket.addEventListener("open", () => processQueue(socket), {
        once: true,
      });
    }
    messageQueue.get(socket).push(buffer);
  }
};

//창류 제어 메서드
export function operation(
  socket,
  house_cde,
  agid,
  akid,
  layerid,
  layersubid,
  opp,
  openRate
) {
  let buffer;
  let arrayBuffer;

  if (opp == 0) {
    arrayBuffer = new ArrayBuffer(7);
    buffer = new Uint8Array(arrayBuffer);
    buffer[0] = CH_LAYER_SUB_STOP;
    buffer[1] = 5;
  } else {
    // 1: 열리고잇다
    //  2: 닫히고 있다
    //  0: stop
    // 열거나 닫히거나 ...  open rate를 가지고 판단
    // 50퍼센트 열려있을 때. os 1 or 70 보내면 열릴 것
    // 70퍼센트인 상태에서 작은 숫자 줘야 닫힘 .
    arrayBuffer = new ArrayBuffer(8);
    buffer = new Uint8Array(arrayBuffer);
    buffer[0] = CH_OPPFIT;
    buffer[1] = 6;
    buffer[7] = Number(openRate);
  }

  buffer[2] = Number(house_cde);
  buffer[3] = Number(agid);
  buffer[4] = Number(akid);
  buffer[5] = Number(layerid);
  buffer[6] = Number(layersubid);

  sendMessage(socket, buffer);
}

export function changeOpenrate(oppstate, openrate) {
  console.log(oppstate, openrate);
}
