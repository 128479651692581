import { AI_PRIORITY, CH_AUTOMANUAL } from "./Consts";

// pl_model - A로메인 학습 모델, B 로메인 학습 모델
// model_option - 0: 생산량 최대화 / 1:비용 최소화 / 2:나의 모델
// priority - 0: 일사량 제어 / 1: 온도 제어 / 2: 습도 제어
//0 : 로메인 생산량 최대화
//1 : 로메인 비용 최소화
//2 : 로메인 나의 모델
const messageQueue = new Map();

const processQueue = (socket) => {
  const queue = messageQueue.get(socket);
  if (queue) {
    while (queue.length > 0 && socket.readyState === WebSocket.OPEN) {
      const buffer = queue.shift();
      socket.send(buffer);
    }
  }
};

const sendMessage = (socket, buffer) => {
  if (socket.readyState === WebSocket.OPEN) {
    socket.send(buffer);
  } else {
    if (!messageQueue.has(socket)) {
      messageQueue.set(socket, []);
      socket.addEventListener("open", () => processQueue(socket), {
        once: true,
      });
    }
    messageQueue.get(socket).push(buffer);
  }
};

export const aiPriority = (
  socket,
  house_cde,
  pl_code,
  pl_model,
  model_option,
  priority
) => {
  let arrayBuffer = new ArrayBuffer(8);
  let buffer = new Uint8Array(arrayBuffer);

  buffer[0] = AI_PRIORITY;
  buffer[1] = +house_cde;
  buffer[2] = +pl_code;
  buffer[3] = +pl_model;
  buffer[4] = +model_option;
  buffer[5] = +priority[0];
  buffer[6] = +priority[1];
  buffer[7] = +priority[2];
  sendMessage(socket, buffer);
};

export const aiChoose = (socket, house_cde, actGrp, opp) => {
  let arrayBuffer = new ArrayBuffer(5);
  let buffer = new Uint8Array(arrayBuffer);

  buffer[0] = CH_AUTOMANUAL;
  buffer[1] = 3;
  buffer[2] = Number(house_cde);
  buffer[3] = Number(actGrp);
  buffer[4] = Number(opp);
  if (opp == "F") {
    // AI
    buffer[4] = 1;
  } else if (opp == "S") {
    //semi ai
    buffer[4] = 2;
  } else if (opp == "A") {
    //auto
    buffer[4] = 3;
  } else if (opp == "M") {
    //manual
    buffer[4] = 4;
  }
  sendMessage(socket, buffer);
};
