import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useStore from "../../store/store";
import { deleteJournal, getJournalDetail } from "../../api/JournalApi";
import General_X from "../../assets/img/General_X.svg";

const Container = styled.div`
  margin: "3.9rem 0 0 4.2rem";
  padding: 1.75rem;
`;
const PageTitle = styled.div`
  padding-bottom: 1rem;
  font-size: 1.125rem;
`;
const JournalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #f7f9f5;
  padding-bottom: 1rem;
  .first-row {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    margin: 0 2.125rem 0 0;
    align-items: center;
    border-bottom: 0.5px solid black;
    height: 3.2rem;
    div {
      grid-template-rows: 1fr;
      padding-bottom: 0;
    }
  }
  .second-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 0.7rem 2.125rem 0 0;
  }
  .first-col {
    font-size: 1.125rem;
    color: #969696;
    border-right: 0.5px solid black;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    div {
      height: 3rem;
      display: flex;
      align-items: center;
    }
  }
  .second-col {
    padding-left: 1rem;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    align-items: center;
    div {
      height: 3rem;
      display: flex;
      align-items: center;
    }
  }
  .third-col {
    display: flex;
    justify-content: end;
    font-size: 0.9rem;
    padding-top: 0.2rem;
  }
  .date-wrapper {
    display: flex;
    flex-direction: column;
  }
  .tdate {
    display: flex;
    justify-content: space-between;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.div`
  grid-area: title;
  font-size: 1.125rem;
`;
const IconWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
const Icon = styled.img`
  display: flex;
  width: 1rem;
  cursor: pointer;
`;
const Button = styled.div`
  display: flex;
  border: 1px solid #5f9137;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
`;

const LOMAINE_PL_CODE = "08001";
const TOMATO_PL_CODE = "02001";

const JournalDetail = () => {
  const { t } = useTranslation("monitoring");

  const location = useLocation();
  const navigate = useNavigate();
  const journal_seq = location.state.journal_seq;

  const { api_key, farm_id, pl_code } = useStore();
  const [journal, setJournal] = useState({});
  const [columns, setColumns] = useState("");

  // const LOMAINE_DICT = {
  //   plantHeight: t("journals.item.plantHeight"),
  //   leafCount: t("journals.item.leafCount"),
  //   leafLength: t("journals.item.leafLength"),
  //   leafWidth: t("journals.item.leafWidth"),
  //   petioleLength: t("journals.item.petioleLength"),
  //   harvestGroup: t("journals.item.harvestGroup"),
  //   harvestWeight: t("journals.item.harvestWeight"),
  //   dailyHarvestWeight: t("journals.item.dailyHarvestWeight"),
  // };

  const LOMAINE_COLUMN_LIST = [
    { dbIdx: "pllnLt", unit: "cm", tIdx: "plantHeight" },
    { dbIdx: "lefCunt", unit: t("journals.unit.pcs"), tIdx: "leafCount" },
    { dbIdx: "lefLt", unit: "cm", tIdx: "leafLength" },
    { dbIdx: "lefBt", unit: "cm", tIdx: "leafWidth" },
    { dbIdx: "petLt", unit: "cm", tIdx: "petioleLength" },
    {
      dbIdx: "hvstGrupp",
      unit: t("journals.unit.point"),
      tIdx: "harvestGroup",
    },
    { dbIdx: "outtrn", unit: "g", tIdx: "harvestWeight" },
    { dbIdx: "hvstDayCo", unit: "g", tIdx: "dailyHarvestWeight" },
  ];

  // const TOMATO_DICT = {
  //   plantHeight: t("journals.item.plantHeight"),
  //   growthLength: t("journals.item.growthLength"),
  //   leafCount: t("journals.item.leafCount"),
  //   leafLength: t("journals.item.leafLength"),
  //   leafWidth: t("journals.item.leafWidth"),
  //   stemThick: t("journals.item.stemThick"),
  //   inflorescenceHeight: t("journals.item.inflorescenceHeight"),
  //   flowerGroup: t("journals.item.leafflowerGroupCount"),
  //   fruitingHerdGroup: t("journals.item.fruitingHerdGroup"),
  //   harvestGroup: t("journals.item.harvestGroup"),
  //   fruitCount: t("journals.item.fruitCount"),
  //   harvestCount: t("journals.item.harvestCount"),
  //   harvestWeight: t("journals.item.harvestWeight"),
  // };

  const TOMATO_COLUMN_LIST = [
    { dbIdx: "pllnLt", unit: "cm", tIdx: "plantHeight" },
    { dbIdx: "grwtLt", unit: "cm", tIdx: "growthLength" },
    { dbIdx: "lefCunt", unit: t("journals.unit.pcs"), tIdx: "leafCount" },
    { dbIdx: "lefLt", unit: "cm", tIdx: "leafLength" },
    { dbIdx: "lefBt", unit: "cm", tIdx: "leafWidth" },
    { dbIdx: "stemThck", unit: "cm", tIdx: "stemThick" },
    { dbIdx: "fcluHg", unit: "cm", tIdx: "inflorescenceHeight" },
    { dbIdx: "flanGrupp", unit: t("journals.unit.point"), tIdx: "flowerGroup" },
    {
      dbIdx: "frtstGrupp",
      unit: t("journals.unit.point"),
      tIdx: "fruitingHerdGroup",
    },
    {
      dbIdx: "hvstGrupp",
      unit: t("journals.unit.point"),
      tIdx: "harvestGroup",
    },
    { dbIdx: "frstCo", unit: t("journals.unit.pcs"), tIdx: "fruitCount" },
    { dbIdx: "hvstCo", unit: t("journals.unit.pcs"), tIdx: "harvestCount" },
    { dbIdx: "outtrn", unit: "g", tIdx: "harvestWeight" },
  ];

  // const LOMAINE_COLUMN_LIST = [
  //   { title: "plantHeight", unit: "cm" },
  //   { title: "leafCount", unit: t("journals.unit.pcs") },
  //   { title: "leafLength", unit: "cm" },
  //   { title: "leafWidth", unit: "cm" },
  //   { title: "petioleLength", unit: "cm" },
  //   { title: "harvestGroup", unit: t("journals.unit.point") },
  //   { title: "harvestWeight", unit: "g" },
  //   { title: "dailyHarvestWeight", unit: "g" },
  // ];

  // const LOMAINE_COLUMN_LIST = [
  //   "plantHeight",
  //   "leafCount",
  //   "leafLength",
  //   "leafWidth",
  //   "petioleLength",
  //   "harvestGroup",
  //   "harvestWeight",
  //   "dailyHarvestWeight",
  // ];
  // const LOMAINE_COLUMN_UNIT = [
  //   "cm",
  //   t("journals.unit.pcs"),
  //   "cm",
  //   "cm",
  //   "cm",
  //   t("journals.unit.point"),
  //   "g",
  //   "g",
  // ];

  const handleDelete = async () => {
    try {
      await deleteJournal(api_key, farm_id, pl_code, journal_seq);
      navigate("/monitoring");
    } catch (error) {
      console.error("Failed to delete journal:", error);
    }
  };

  const handleClose = () => {
    navigate("/monitoring");
  };

  useEffect(() => {
    getJournalDetail(api_key, farm_id, pl_code, journal_seq)
      .then((res) => setJournal(res))
      .catch((error) =>
        console.error("Failed to fetch journal details:", error)
      );
  }, [pl_code]);

  return (
    <>
      {journal ? (
        <Container>
          <PageTitle>{t("title")}</PageTitle>
          <JournalContainer>
            <TitleWrapper>
              <Title>
                {journal.date?.slice(0, 10)} {t("title")}
              </Title>
              <IconWrapper>
                <Button
                  onClick={() => {
                    navigate(`/monitoring/journals/${journal_seq}/edit`, {
                      state: { journal_seq: journal_seq },
                    });
                  }}
                >
                  {t("general.edit")}
                </Button>
                <Button onClick={handleDelete}> {t("general.delete")}</Button>
                <Icon src={General_X} onClick={handleClose} />
              </IconWrapper>
            </TitleWrapper>
            <div className="first-row">
              <div className="first-col">{t("plantId")}</div>
              <div className="second-col">{journal.pl_id_cde}</div>
              <div className="third-col">
                <div className="date-wrapper">
                  <div className="tdate">
                    {t("journals.transplanting.date")}:{" "}
                    {journal.transplantingDate?.slice(0, 10)}
                    <div>
                      ({journal.transplantingTime}
                      {t("journals.transplanting.count")})
                    </div>
                  </div>
                  <div>
                    {t("journals.create.date")}: {journal.date}
                  </div>
                </div>
              </div>
            </div>
            <div className="second-row">
              <div className="first-col">
                {pl_code === LOMAINE_PL_CODE
                  ? LOMAINE_COLUMN_LIST.map((col) => (
                      <div key={col}>{t(`journals.item.${col.tIdx}`)}</div>
                    ))
                  : pl_code === TOMATO_PL_CODE
                  ? TOMATO_COLUMN_LIST.map((col) => (
                      <div key={col}>{t(`journals.item.${col.tIdx}`)}</div>
                    ))
                  : null}
              </div>
              <div className="second-col">
                {journal && pl_code === LOMAINE_PL_CODE
                  ? LOMAINE_COLUMN_LIST.map((col) => (
                      <div key={col}>
                        {journal[col.dbIdx]}
                        {col.unit}
                      </div>
                    ))
                  : pl_code === TOMATO_PL_CODE
                  ? TOMATO_COLUMN_LIST.map((col) => (
                      <div key={col}>
                        {journal[col.dbIdx]}
                        {col.unit}
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </JournalContainer>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default JournalDetail;
