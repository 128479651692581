import React, { useState } from "react";
import styled from "styled-components";
import useStore from "../../store/store";
import Settings_Arrow_Active from "../../assets/img/Settings_Arrow_Active.svg";
import Settings_Arrow_Inactive from "../../assets/img/Settings_Arrow_Inactive.svg";
import { useNavigate } from "react-router-dom";
import { setFarm } from "../../api/AuthApi";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
  padding: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProgressLineWrapper = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  height: 7px;
  background-color: #f1f5ed;
  margin: 1rem 0 2rem 0;
  border-radius: 3px;
`;

const Progress = styled.div`
  position: absolute;
  display: flex;
  justify-self: start;
  width: ${(props) => props.width || "10rem"};
  height: 7px;
  background-color: #cfdec3;
  border-radius: 3px;
`;

const CircleWrapper = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 90vw;
  justify-items: center;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  &.done {
    color: #fff;
    background-color: #5f9137;
  }
  &.in_progress {
    color: #fff;
    background-color: #5f9137;
  }
  &.not_started {
    color: #5f9137;
    background-color: #fff;
    border: 1px solid #5f9137;
  }
`;

const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 1.4rem;
  border-radius: 3rem;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  cursor: pointer;
  &.done {
    background-color: #f1f5ed;
    color: #969696;
  }
  &.in_progress {
    background-color: #5f9137;
    color: #fff;
  }
  &.not_started {
    background-color: #f1f5ed;
    color: #585858;
  }
`;

const Icon = styled.img`
  padding-left: 1rem;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
`;

const RadioButton = styled.input.attrs({ type: "radio" })`
  margin: 0 1rem;
  appearance: none;
  border: max(1px, 0.1em) solid #5f9137;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  transition: border 0.1s ease-in-out;
  &:checked {
    border: 0.6em solid #5f9137;
  }
  &:hover {
    box-shadow: 0 0 0 max(4px, 0.2em) lightgray;
    cursor: pointer;
  }
  &:focus-visible {
    outline: max(2px, 0.1em) dotted #5f9137;
    outline-offset: max(2px, 0.1em);
  }
  &:disabled {
    box-shadow: none;
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const OptionText = styled.span`
  color: ${(props) => (props.faded ? "#969696" : "#4D4D4D")};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const SaveButton = styled.div`
  display: flex;
  justify-content: center;
  width: 3.75rem;
  height: 1.875rem;
  font-size: 1.125rem;
  border-radius: 3px;
  text-align: center;
  align-items: center;
  background-color: #5f9137;
  color: #ffffff;
`;

const Settings = () => {
  const { t } = useTranslation("settings");
  const { t: tCommon } = useTranslation("common");
  const {
    api_key,
    user_id,
    farm_id,
    pl_code,
    pl_model,
    device,
    setFarmId,
    setPlCode,
    setPlModel,
    setDevice,
  } = useStore();

  const [selectedFarm, setSelectedFarm] = useState(farm_id);
  const [selectedCrop, setSelectedCrop] = useState(pl_code);
  const [selectedDevice, setSelectedDevice] = useState(device);
  const [selectedModel, setSelectedModel] = useState(pl_model);
  const [currentStage, setCurrentStage] = useState(farm_id ? 4 : 1); // 설정값 있는 경우는 선택된 것 다 보여주기
  const [farthestStage, setFarthestStage] = useState(farm_id ? 4 : 1);
  const navigate = useNavigate();

  // 나중에 이거도 가져와야 되는 거 같은데..
  const farms = [
    { name: "평창 A동", code: "KRAI200001" },
    { name: "아산 A동", code: "KRAI100001" },
    { name: "Goat Demo", code: "KRAI000001" },
  ];

  const crops = [
    { name: "로메인", code: "08001" },
    { name: "토마토", code: "02001" },
  ];

  const equipments = [
    { name: "장비 A", code: 0 },
    { name: "장비 B", code: 1 },
    { name: "장비 C", code: 2 },
  ];

  const models = [
    { name: "A 로메인 학습 모델", code: 0 },
    { name: "A 토마토 학습 모델", code: 1 },
  ];

  const handleOptionClick = (stage, code) => {
    if (stage === 1) {
      setSelectedFarm(code);
      setCurrentStage(2);
      setFarthestStage((prev) => Math.max(prev, 2));
    } else if (stage === 2) {
      setSelectedCrop(code);
      setCurrentStage(3);
      setFarthestStage((prev) => Math.max(prev, 3));
    } else if (stage === 3) {
      setSelectedDevice(code);
      setCurrentStage(4);
      setFarthestStage((prev) => Math.max(prev, 4));
    } else if (stage === 4) {
      setSelectedModel(code);
      setCurrentStage(5); // save 버튼용
    }
  };

  const handleButtonClick = (stage) => {
    if (stage <= farthestStage) {
      setCurrentStage(stage);
    }
  };

  const getButtonClass = (stage) => {
    if (stage < currentStage) {
      return "done";
    } else if (stage === currentStage) {
      return "in_progress";
    } else {
      return "not_started";
    }
  };

  const handleSaveClick = () => {
    setFarmId(selectedFarm);
    setPlCode(selectedCrop);
    setDevice(selectedDevice);
    setPlModel(selectedModel);
    setFarm(
      api_key,
      user_id,
      selectedFarm,
      selectedCrop,
      selectedDevice,
      selectedModel
    );
    navigate("/dashboard");
  };

  return (
    <Container>
      <ContentWrapper>
        <ProgressLineWrapper>
          <Progress width={`${22.5 * currentStage}vw`} />
        </ProgressLineWrapper>
        <CircleWrapper>
          <Circle className={currentStage >= 1 ? "done" : "not_started"}>
            1
          </Circle>
          <Circle className={currentStage >= 2 ? "done" : "not_started"}>
            2
          </Circle>
          <Circle className={currentStage >= 3 ? "done" : "not_started"}>
            3
          </Circle>
          <Circle className={currentStage >= 4 ? "done" : "not_started"}>
            4
          </Circle>
        </CircleWrapper>
        <ColumnWrapper>
          <Column>
            <Button
              className={getButtonClass(1)}
              onClick={() => handleButtonClick(1)}
            >
              {t("greenhouse.title")}
              <Icon
                src={
                  getButtonClass(1) === "in_progress"
                    ? Settings_Arrow_Active
                    : Settings_Arrow_Inactive
                }
              />
            </Button>
            {(farthestStage >= 1 || currentStage === 1) &&
              farms.map((opt) => (
                <Option key={opt.code}>
                  <RadioButton
                    checked={selectedFarm === opt.code}
                    onChange={() => handleOptionClick(1, opt.code)}
                    disabled={currentStage !== 1}
                  />
                  <OptionText
                    faded={selectedFarm !== opt.code && currentStage > 1}
                  >
                    {t(`greenhouse.options.${opt.code}`)}
                  </OptionText>
                </Option>
              ))}
          </Column>
          <Column>
            <Button
              className={getButtonClass(2)}
              onClick={() => handleButtonClick(2)}
            >
              {t("crop.title")}
              <Icon
                src={
                  getButtonClass(2) === "in_progress"
                    ? Settings_Arrow_Active
                    : Settings_Arrow_Inactive
                }
              />
            </Button>
            {farthestStage >= 2 &&
              currentStage >= 2 &&
              crops.map((opt) => (
                <Option key={opt.code}>
                  <RadioButton
                    checked={selectedCrop === opt.code}
                    onChange={() => handleOptionClick(2, opt.code)}
                    disabled={currentStage !== 2}
                  />
                  <OptionText
                    faded={selectedCrop !== opt.code && currentStage > 2}
                  >
                    {t(`crop.options.${opt.code}`)}
                  </OptionText>
                </Option>
              ))}
          </Column>
          <Column>
            <Button
              className={getButtonClass(3)}
              onClick={() => handleButtonClick(3)}
            >
              {t("equipment.title")}
              <Icon
                src={
                  getButtonClass(3) === "in_progress"
                    ? Settings_Arrow_Active
                    : Settings_Arrow_Inactive
                }
              />
            </Button>
            {farthestStage >= 3 &&
              currentStage >= 3 &&
              equipments.map((opt) => (
                <Option key={opt.code}>
                  <RadioButton
                    checked={selectedDevice === opt.code}
                    onChange={() => handleOptionClick(3, opt.code)}
                    disabled={currentStage !== 3}
                  />
                  <OptionText
                    faded={selectedDevice !== opt.code && currentStage > 3}
                  >
                    {t(`equipment.options.${opt.code}`)}
                  </OptionText>
                </Option>
              ))}
          </Column>
          <Column>
            <Button
              className={getButtonClass(4)}
              onClick={() => handleButtonClick(4)}
            >
              {t("model.title")}
              <Icon
                src={
                  getButtonClass(4) === "in_progress"
                    ? Settings_Arrow_Active
                    : Settings_Arrow_Inactive
                }
              />
            </Button>
            {farthestStage >= 4 &&
              currentStage >= 4 &&
              models.map((opt) => (
                <Option key={opt.code}>
                  <RadioButton
                    checked={selectedModel === opt.code}
                    onChange={() => handleOptionClick(4, opt.code)}
                    disabled={currentStage !== 4}
                  />
                  <OptionText
                    faded={selectedModel !== opt.code && currentStage > 4}
                  >
                    {t(`model.options.${opt.code}`)}
                  </OptionText>
                </Option>
              ))}
          </Column>
        </ColumnWrapper>
      </ContentWrapper>
      <ButtonContainer>
        <SaveButton onClick={handleSaveClick} disabled={currentStage !== 5}>
          {tCommon("save")}
        </SaveButton>
      </ButtonContainer>
    </Container>
  );
};

export default Settings;
