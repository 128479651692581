import { instance } from "./Axios";

export const insertAlarms = async (api_key, farm_id, data, alarmDates) => {
  const response = await instance.put("dbserver/goatfarm/insertalarms", {
    api_key: api_key,
    farm_id: farm_id,
    data: data,
    alarmDates: alarmDates,
  });
  return response.data;
};
/*
{
    "api_key": 1234,
    "data" : {
              "culvDate":"2024-11-23 21:42:25", 
              "plCode" : "08001"  // 로메인
    },
    //A0001 : 생육조사 알람
    //A0002 : 수확 알람
    "alarmDates":[
      {"date":"2024-11-23 21:42:25", "alCode":"A0001", "readStatus":"N"},
      {"date":"2024-11-23 21:42:27", "alCode":"A0001", "readStatus":"N"},
      {"date":"2024-11-23 21:42:21", "alCode":"A0001", "readStatus":"N"},
      {"date":"2024-11-23 21:42:24", "alCode":"A0001", "readStatus":"N"},
      {"date":"2024-11-23 21:42:11", "alCode":"A0001", "readStatus":"N"},
      {"date":"2024-11-23 21:42:12", "alCode":"A0001", "readStatus":"N"},
      {"date":"2024-11-23 21:42:13", "alCode":"A0001", "readStatus":"N"},
      {"date":"2024-11-23 21:42:14", "alCode":"A0001", "readStatus":"N"},
      {"date":"2024-11-23 21:42:15", "alCode":"A0001", "readStatus":"N"},
      {"date":"2024-11-23 21:42:16", "alCode":"A0001", "readStatus":"N"},
      {"date":"2024-11-23 21:42:17", "alCode":"A0002", "readStatus":"N"}
    ]
}
}

//culvDate : 정식일
//plCode : 작물코드
//alCode : 알람코드
//alarmDates : 알람일
//readStatus : Y=익음, N=안읽음
*/

export const getAlarms = async (api_key, farm_id, current_date) => {
  const response = await instance.post("dbserver/goatfarm/getalarms", {
    api_key: api_key,
    farm_id: farm_id,
    current_date: current_date,
  });
  return response.data.alarms;
};

/*
{
    "api_key": 1234,
    "farm_id": "AIF001",
    "current_date" : "1717392674718"
}
*/

export const readAlarms = async (
  api_key,
  farm_id,
  date,
  alCode,
  readStatus
) => {
  const response = await instance.put("dbserver/goatfarm/readalarms", {
    api_key: api_key,
    farm_id: farm_id,
    date: date,
    alCode: alCode,
    readStatus: readStatus,
  });
  return response.data;
};
