import React from "react";

export const RUN_BRIEFING = 100;
export const CHECKALIVE = 63;
export const C_WEB_CLIENT_LOGIN = 10;
export const C_WEB_CLIENT_CONNECTED = 11;
export const C_WEB_CLIENT_RECONNECTED = 12;
export const C_WEB_CLIENT_CHECKCONNTION = 13;
export const C_ISLOCAL = 25;
export const C_WEB_CLIENT_CONNECTED_COMPLETE = 23;
export const C_WEB_CLIENT_CLOSE = 24;
//C_WEB_CLIENT_CONNECTED_COMPLETE_FORECAST =25 ;
export const R_DATA = 21;
export const R_DATA_COMMON = 22;

export const CH_ACTGRP_ALL_STOP = 45;
export const CH_ACTGRP_ACT_STOP = 46;

export const CH_AUTOMANUAL = 31;
export const CH_ANAL_OPEN = 32;
export const CH_ONOFF = 33;
export const CH_LAYER_SUB_STOP = 36;
export const CH_OPPFIT = 34;
export const CH_ONOFF_SUB_ALL = 57;
export const CH_SINGLE_VALUE = 34;
export const CH_MULTY_VALUE = 35;
export const CH_DYNAMIC_VALUE = 37;
export const CH_WINDDIR = 38;
export const CH_LIMIT_VALUE = 39;
export const CH_CONDITION_VALUE = 40;
export const CH_CONDITION_PRIORITY = 41;
export const CH_OPENRATE = 42;
export const CH_OPENRATE_RESET = 43;
export const CH_RUNSTD = 44;
export const CH_LED_24_ONOFF = 47; // 24V
export const CH_LED_24_CONDITION = 71; // 24V
export const CH_LED_220_ONOFF = 48; // 220V
export const CH_LED_220_CONDITION = 72; // 220V
export const CH_LED_TAPO_ONOFF = 49; // LED TAPO
export const CH_LED_TAPO_CONDITION = 50; // LED TAPO

export const CH_PERIORD = 51;
export const CH_PRIORITY_DETAIL = 52;
export const CH_CUR_RULE = 54;
export const CH_RULE_INTERVAL = 55;

export const INS_PERIORD = 58;
export const DEL_PERIORD = 59;
export const UP_CORECTION = 64;
export const UP_ETC = 65;
export const UP_ROLL = 66;

export const AI_PRIORITY = 81;
export const AI_CHOOSE = 82;

export const WARNING = 99;
export const TEMPH = 1;
export const TEMPL = 2;

export const NOTICE = 40;
export const NOTICE_VALUE = 53;

export const CH_SENSOR_ALERT = 61;
export const CH_SENSOR_ALERT_WS = 62;
export const CH_REMOTE = 56;
