import React, { useEffect, useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import Sortable from "sortablejs/modular/sortable.core.esm.js";

import useStore from "../../store/store.js";
import { useSocket } from "../../socket/Socket.js";
import { aiPriority } from "../../socket/ai_control.js";
import { editModel } from "../../api/ModelApi";

import General_File from "../../assets/img/General_File.svg";
import General_X from "../../assets/img/General_X.svg";
import Button_Arrow_Active from "../../assets/img/Button_Arrow_Active.svg";
import General_Draggable from "../../assets/img/General_Draggable.svg";
import General_Settings from "../../assets/img/General_Settings.svg";
import Info_Square from "../../assets/img/Info_Square.svg";
import Modal from "../../components/Modal.js";
import Card from "../../components/Card.js";
import { useTranslation } from "react-i18next";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: baseline;
  color: #4d4d4d;
`;
const ModalBoxSetup = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 55%;
  overflow: hidden;
  height: 32rem;
  margin: 12vh 0 0 8vw;
  padding: 1rem 2rem;
  box-sizing: border-box;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 3px;
`;
const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: rgba(0, 0, 0, 0.5);
`;
const CloseIcon = styled.img`
  width: 0.7rem;
  position: absolute;
  right: 1rem;
  cursor: pointer;
`;
const FileWrapper = styled.div`
  display: flex;
`;
const Icon = styled.img`
  padding: 0 0.5rem;
  width: ${(props) => props.width};
`;
const ExclamationIcon = styled.img`
  height: ${(props) => props.height || "1rem"};
  padding-left: 0.5rem;
  cursor: pointer;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 0.5rem;
  .title {
    font-size: 1.125rem;
    padding-top: 0.5rem;
  }
`;
const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Text = styled.div`
  text-align: left;
  line-height: 1.8rem;
  font-size: 1rem;
  white-space: pre-line;
`;
const SelectField = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 1.125rem;
  line-height: 2rem;
  list-style: none;
  padding-left: 0px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 12.5rem;
    border: 1px solid #5f9137;
    border-radius: 10rem;
    padding: 0.25rem 1.2rem;
    margin-top: 0.5rem;
    background-color: #ffffff;
    cursor: pointer;
  }
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 2.56rem;
  margin-top: 1rem;
  background-color: #5f9137;
  color: #ffffff;
  font-size: 1.125rem;
  border-radius: 20px;
  &&.inactive {
    color: #4d4d4d;
    background-color: #f7f9f5;
  }
  cursor: pointer;
`;
const Arrow = styled.img`
  width: 1.25rem;
  padding-left: 0.5rem;
`;
const DataButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 12.5rem;
  padding-top: 2rem;
`;
const MiniButton = styled.div`
  width: 3.1rem;
  padding: 0.2rem;
  margin-left: 0.5rem;
  text-align: center;
  background-color: #5f9137;
  color: #ffffff;
  border-radius: 1rem;
  &.inactive {
    background-color: #cfdec3;
    color: #4d4d4d;
  }
  cursor: pointer;
`;
const SaveButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 3rem;
  padding: 0.2rem;
  background-color: #5f9137;
  color: #ffffff;
  border-radius: 3px;
`;

const ModalSetupModel = ({
  open,
  close,
  execute,
  house_cde,
  pl_code,
  pl_model,
  model_name,
  model_option,
  options,
  priority,
  relearn,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "aiController" });

  const { api_key, farm_id } = useStore();
  const [state, setState] = useState({
    options: [],
    model_option: null,
    priority: [],
    relearn: false,
  });
  const [isInitialized, setIsInitialized] = useState(false);

  // modal창 띄우기
  const [modal, setModal] = useState(false);
  const handleModal = () => {
    setModal(!modal);
  };

  // 웹소켓
  const { socket } = useSocket();

  const content = useMemo(
    () =>
      modal && (
        <Card
          title={t("modal.relearn.title")}
          close={handleModal}
          text={t("modal.relearn.content")}
          note={t("modal.relearn.note")}
        />
      ),
    [modal]
  );
  const DICT = {
    0: t("option.solar"),
    1: t("option.temperature"),
    2: t("option.humidity"),
  };

  const getOrder = () => {
    const items = document.querySelectorAll("#items li");
    const order = [];
    items.forEach((item) => {
      order.push(item.getAttribute("data-id"));
    });
    setState((cur) => {
      let newState = { ...cur };
      newState.priority = order;
      return newState;
    });
  };

  const handleSelected = (id) => {
    setState((cur) => {
      let newState = { ...cur };
      newState.model_option = id;
      return newState;
    });
  };

  const handleRelearn = () => {
    setState((cur) => {
      let newState = { ...cur };
      newState.relearn = !newState.relearn;
      return newState;
    });
  };

  const handleSubmit = async () => {
    await editModel(
      api_key,
      farm_id,
      pl_code,
      pl_model,
      model_name,
      state.model_option,
      state.priority,
      state.relearn
    );
    // 웹소켓 통신
    aiPriority(socket, house_cde, pl_code, pl_model, model_option, priority);
    execute();
  };

  const setTitle = () => {
    const title = state.options.find((opt) => opt.id === state.model_option);
    return title?.name;
  };

  useEffect(() => {
    const el = document.getElementById("items");
    Sortable.create(el); // draggable 만들기
  }, []);

  useEffect(() => {
    document.addEventListener("dragend", getOrder);
    return () => {
      document.removeEventListener("dragend", getOrder);
    };
  }, []);

  useEffect(() => {
    setState({
      options: options || [],
      model_option: model_option ?? null,
      priority: priority || [],
      relearn: relearn || false,
    });
    setIsInitialized(true);
  }, [options, model_option, priority, relearn]);

  return (
    <>
      {open ? (
        <ModalContainer>
          <ModalBackground onClick={close} />
          <ModalBoxSetup>
            <CloseIcon src={General_X} onClick={close} />
            <FileWrapper>
              <Icon width="2.18rem" src={General_File} />
              <TitleWrapper>
                <div className="title">{t(`model.${model_name}`)}</div>
                <div className="description">{t(`mode.${setTitle()}`)}</div>
              </TitleWrapper>
            </FileWrapper>
            <ColumnWrapper>
              <Column>
                {isInitialized && state.options.length !== 0 ? (
                  state.options.map((opt) => (
                    <Button
                      key={opt.id}
                      className={
                        opt.id !== state.model_option ? "inactive" : ""
                      }
                      onClick={() => handleSelected(opt.id)}
                    >
                      {t(`mode.${opt.name}`)}
                      <Arrow src={Button_Arrow_Active} />
                    </Button>
                  ))
                ) : (
                  <></>
                )}
              </Column>
              <Column>
                <Text>{t("modal.description")}</Text>
                <SelectField id="items">
                  {isInitialized && state.priority.length !== 0 ? (
                    state.priority.map((val) => (
                      <li data-id={val} key={val}>
                        <Icon width="1rem" src={General_Draggable} />
                        {DICT[val]}
                        <Icon width="1rem" src={General_Settings} />
                      </li>
                    ))
                  ) : (
                    <></>
                  )}
                </SelectField>
                <DataButtonWrapper>
                  <div>{t("modal.relearn.title")}</div>
                  <ExclamationIcon
                    src={Info_Square}
                    alt="re-learn?"
                    onClick={handleModal}
                  />
                  {state.relearn ? (
                    <MiniButton onClick={handleRelearn}>
                      {t("modal.on")}
                    </MiniButton>
                  ) : (
                    <MiniButton onClick={handleRelearn} className="inactive">
                      {t("modal.off")}
                    </MiniButton>
                  )}
                </DataButtonWrapper>
              </Column>
            </ColumnWrapper>
            <SaveButton onClick={handleSubmit}>{t("modal.save")}</SaveButton>
          </ModalBoxSetup>
        </ModalContainer>
      ) : (
        <></>
      )}
      {modal && (
        <Modal
          show={modal}
          content={content}
          reject={handleModal}
          style={{ height: "13rem" }}
        />
      )}
    </>
  );
};

export default ModalSetupModel;
