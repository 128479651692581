// store.js
import { create } from "zustand";
const useStore = create((set) => ({
  api_key: "1234",
  isLoggedIn: false,
  user_id: "daibuser",
  initial_farm_id: "KRAI000001", // 아산 initial state as null
  houseCode: -1,
  actGrp: -1,
  farm_id: null,
  pl_code: null, // 작물 코드
  pl_model: null, // A 로메인 모델
  device: null, // initial state as null
  socket_url: null,
  setApiKey: (api_key) => set({ api_key }),
  setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn }),
  setUserId: (user_id) => set({ user_id }),
  setHouseCode: (houseCode) => set({ houseCode }),
  setActGrp: (actGrp) => set({ actGrp }),
  setFarmId: (farm_id) => set({ farm_id }),
  setPlCode: (pl_code) => set({ pl_code }),
  setDevice: (device) => set({ device }),
  setPlModel: (pl_model) => set({ pl_model }),
  setSocketUrl: (socket_url) => set({ socket_url }),
}));

export default useStore;
