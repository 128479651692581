import React from "react";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-height: 9.5rem;
  margin-bottom: 1rem;
  background-color: #5f913713;
  border-radius: 1.6rem;
  position: relative;
  overflow: hidden;
  background: #5f913713;
  background-image: linear-gradient(
    to right,
    #5f913713 0%,
    #dddddd 20%,
    #5f913713 40%,
    #5f913713 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 210px;
  animation: ${shimmer} 1.2s infinite linear;
`;

const SkeletonHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 2rem;
  width: 100%;
  margin-bottom: 1rem;
`;

const SkeletonTitle = styled.div`
  width: 50%;
  height: 1.5rem;
  background: #5f913713;
  border-radius: 0.25rem;
`;

const SkeletonBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const SkeletonSkyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
`;

const SkeletonImage = styled.div`
  width: 6.8rem;
  height: 6.8rem;
  background: #5f913713;
  border-radius: 50%;
`;

const SkeletonContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

const SkeletonTemperature = styled.div`
  width: 3rem;
  height: 2.5rem;
  background: #5f913713;
  border-radius: 0.25rem;
`;

const SkeletonTemperatureWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SkeletonTMNXWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  div {
    width: 3rem;
    height: 1.25rem;
    background: #5f913713;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
  }
`;

const SkeletonTimeSunWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  div {
    width: 4rem;
    height: 0.9rem;
    background: #5f913713;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
  }
`;

const SkeletonOtherWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  div {
    width: 3rem;
    height: 1.125rem;
    background: #5f913713;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
  }
`;

const ExtWeatherSkeleton = () => {
  return (
    <SkeletonWrapper>
      <SkeletonHeader>
        <SkeletonTitle />
      </SkeletonHeader>
      <SkeletonBody>
        <SkeletonSkyWrapper>
          <SkeletonImage />
          <SkeletonContentWrapper>
            <SkeletonTemperatureWrapper>
              <SkeletonTemperature />
              <SkeletonTMNXWrapper>
                <div />
                <div />
              </SkeletonTMNXWrapper>
            </SkeletonTemperatureWrapper>
            <SkeletonTimeSunWrapper>
              <div />
              <div />
            </SkeletonTimeSunWrapper>
          </SkeletonContentWrapper>
        </SkeletonSkyWrapper>
        <SkeletonOtherWrapper>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </SkeletonOtherWrapper>
      </SkeletonBody>
    </SkeletonWrapper>
  );
};
export default ExtWeatherSkeleton;
