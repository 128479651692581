import { utils } from "./utils.js";
import { winddirection } from "./weatherDictionary.js";

export function refreshData(value, item_sensor) {
  //fixNum 센서별 소숫점 자릿수
  let txt = value.toFixed(1);
  if (value == 6454.6) {
    txt = "--";
  }
  if (value == -99) txt = "--";

  //======================================
  //센서 명칭 및 단위  실시간으로 넘어오는 데이터에 포함된 것이 아닌 최초 tns.tenefarm.com 에서 가져옴
  //item_sensor.sensorNam = args.sm + " " +  (args.un != null ? ("("+args.un+")") : "");
  //센서 측정값
  item_sensor.value = value;
  //======================================
}
