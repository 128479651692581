import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import formatDate from "../../utils/formatDate";
import { getSensorNode } from "../../api/SocketApi";
import WeatherDictionary from "../../socket/weatherDictionary";

import ExtWeatherSkeleton from "./ExtWeatherSkeleton";
import Title from "../../components/layout/Title";
import Radar from "../../assets/img/Menu_Radar.svg";
import Weather_Rainy_1 from "../../assets/img/Weather_Rainy_1.svg";
import Weather_Sunny_0 from "../../assets/img/Weather_Sunny_0.svg";
import useStore from "../../store/store";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-height: 9.5rem;
  margin-bottom: 1rem;
  background-color: #5f913713;
  border-radius: 1.6rem;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const SkyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
`;
const Image = styled.img`
  width: 6.8rem;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 1rem;
`;

const Temperature = styled.div`
  font-size: 2.5rem;
`;
const TemperatureWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TMNXWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  div {
    display: flex;
    font-size: 1.25rem;
    text-align: end;
  }
  .TMN {
    color: #3d7ae8;
  }
  .TMX {
    color: #cd3534;
  }
`;
const TimeSunWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  font-size: 0.9rem;
  .sun {
    color: #929292;
  }
`;
const OtherWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  div {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
  }
  .title {
    color: #8b8b8b;
    font-size: 1rem;
  }
`;

let date = new Date().getTime();

const Weather_Icon = {
  0: Weather_Sunny_0, // CODE : skyStep 이나 rainStep 참고
  1: Weather_Rainy_1,
};

const ExtWeather = ({
  weatherstation,
  // temperature_min,
  // temperature_max,
  sunrise,
  sunset,
}) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "extWeather" });
  const { farm_id } = useStore();
  const { winddirection, skyStep, rainStep } = WeatherDictionary();
  const [time, setTime] = useState(formatDate(date, "HH:MM"));
  const [keys, setKeys] = useState({}); // tenefarm 정보
  const [data, setData] = useState({});

  // 데이터 구조
  // weatherstation [{sensorID: 2, value: 51.2}, {…}, {…}, {…}, {…}, {…}]
  // sunset 19:43
  // sunrise 05:09

  const findWindDirection = (val) => {
    let result;
    result =
      val >= 0 && val < 45
        ? 0
        : val >= 45 && val < 90
        ? 1
        : val > 90 && val < 135
        ? 2
        : val >= 135 && val < 180
        ? 3
        : val >= 180 && val < 225
        ? 4
        : val >= 225 && val < 270
        ? 5
        : val >= 270 && val < 315
        ? 6
        : 7;
    return result;
  };

  useEffect(() => {
    // tenefarm 데이터에서 해당되는 센서 노드 키값 가져오기
    if (farm_id) {
      getSensorNode(farm_id)
        .then((res) => {
          setKeys((cur) => {
            let newState = { ...cur };
            newState.temperature = res.find((x) => x.sm === "외부온도").key;
            newState.humidity = res.find((x) => x.sm === "외부습도").key;
            newState.sky = res.find((x) => x.sm === "날씨").key;
            newState.windVelocity = res.find((x) => x.sm === "풍속").key;
            newState.windDirection = res.find((x) => x.sm === "풍향").key;
            newState.solarRadiation = res.find((x) => x.sm === "일사").key;
            return newState;
          });
        })
        .catch((error) => console.error("Error getting sensor node:", error));
    }
  }, [farm_id]);

  useEffect(() => {
    // 가져온 키 값으로 소켓에서 받은 데이터에서 값 찾아서 저장
    // (소켓 값이 변할 때마다 반복)
    setData((cur) => {
      let newState = { ...cur };
      if (weatherstation) {
        newState.temperature = weatherstation.find(
          (x) => x.sensorID === keys.temperature
        )?.value;
        newState.humidity = weatherstation.find(
          (x) => x.sensorID === keys.humidity
        )?.value;
        newState.sky = weatherstation.find(
          (x) => x.sensorID === keys.sky
        )?.value;
        newState.windDirection =
          winddirection[
            findWindDirection(
              weatherstation.find((x) => x.sensorID === keys.windDirection)
                ?.value
            )
          ];
        newState.windVelocity = weatherstation.find(
          (x) => x.sensorID === keys.windVelocity
        )?.value;
        newState.solarRadiation = weatherstation.find(
          (x) => x.sensorID === keys.solarRadiation
        )?.value;
      }
      if (sunrise) {
        newState.sunrise = sunrise;
        newState.sunset = sunset;
      }
      return newState;
    });

    // 시간 설정
    let newDate = new Date().getTime();
    setTime(formatDate(newDate, "HH:MM"));
  }, [weatherstation]);

  if (!weatherstation) {
    return <ExtWeatherSkeleton />;
  }
  return (
    <>
      <Container>
        <Header>
          <Title
            src={Radar}
            title={t("title")}
            padding="0rem"
            color="#000000"
          />
        </Header>
        {weatherstation ? (
          <Body>
            <SkyWrapper>
              <Image src={Weather_Icon[data.sky > 0 ? 1 : 0]} alt="" />
              <ContentWrapper>
                <TemperatureWrapper>
                  <Temperature>
                    {data.temperature}
                    ˚C
                  </Temperature>
                  <TMNXWrapper>
                    <div>{rainStep[data.sky]} </div>
                    <div>
                      {/* <div className="TMN">{temperature_min}˚</div> /{" "}
                      <div className="TMX">{temperature_max}˚</div> */}
                    </div>
                  </TMNXWrapper>
                </TemperatureWrapper>
                <TimeSunWrapper>
                  <div>
                    {t("category.time")} {time}{" "}
                  </div>
                  <div className="sun">
                    {t("category.sunrise")} {data.sunrise}{" "}
                    {t("category.sunset")} {data.sunset}
                  </div>
                </TimeSunWrapper>
              </ContentWrapper>
            </SkyWrapper>
            <OtherWrapper>
              <div className="title">{t("category.humidity")}</div>
              <div>{data.humidity}%</div>
              <div className="title">{t("category.solarRadiation")}</div>
              <div>{data.solarRadiation}W/㎡</div>
              <div className="title">{t("category.windVelocity")}</div>
              <div>{data.windVelocity}m/s</div>
              <div className="title">{t("category.windDirection")}</div>
              <div>{data.windDirection}</div>
            </OtherWrapper>
          </Body>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default ExtWeather;
