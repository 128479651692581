import React from "react";
import styled from "styled-components";

const Container = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const TruncateText = ({ text, maxLength }) => {
  if (text) {
    if (text.length <= maxLength) {
      return <div>{text}</div>;
    }
    return <Container>{text.slice(0, maxLength)}...</Container>;
  }
};

export default TruncateText;
