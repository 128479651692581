import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 6px;
  background-color: #d9d9d9;
  border-radius: 12px;
  margin: 0.5rem 0;
  overflow: hidden;
`;

const Progress = styled.div`
  width: ${(props) => props.width}%;
  height: 6px;
  padding: 0;
  text-align: center;
  background-color: ${(props) => props.color};
`;

const ProgressBar = ({ value, minimum, maximum, status }) => {
  const calculated = ((value - minimum) * 100) / (maximum - minimum);

  return (
    <Container>
      <Progress width={calculated <= 5 ? 5 : calculated} color={status} />
    </Container>
  );
};

export default ProgressBar;
