import { tnsInstance } from "./Axios";
import { getFarmConfig } from "../utils/getFarmConfig";

export const getSensorNode = async (farm_id) => {
  const farmConfig = getFarmConfig(farm_id);

  const response = await tnsInstance.get("ict/getfarminfo", {
    params: {
      usr_id: farmConfig.id,
    },
  });
  return response.data.data[0].ws[0].sensornode[0].sensor;
};

export const getHouseSensorNode = async (farm_id) => {
  const farmConfig = getFarmConfig(farm_id);

  const response = await tnsInstance.get("ict/getfarminfo", {
    params: {
      usr_id: farmConfig.id,
    },
  });

  return response.data.data[0].control[0].house[0].sensornode;
};

export const getHouse = async (farm_id) => {
  const farmConfig = getFarmConfig(farm_id);

  const response = await tnsInstance.get("ict/getfarminfo", {
    params: {
      usr_id: farmConfig.id,
    },
  });

  return response.data.data[0].control[0];
};

/*
  - arcgroup(환기 그룹, 보온 그룹, 차광 그룹 등등 그룹)
    - actkind(환기 그룹 내 천창, 측창 종류)
      - actlayer(창: 1중 2중 3중이냐 구분)
        - actlayersub(좌 우 후 등 : 창)
      - actonoff(단계가 1개인 것 : 환기팬)
*/
