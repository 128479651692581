import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as actonoff from "../../socket/actOnOff.js";
import * as actwindow from "../../socket/actWindow.js";
import { getHouse } from "../../api/SocketApi.js";
import ControllerWindows from "./ControllerWindows.js";
import ControllerEtc from "./ControllerEtc.js";
import ToggleSwitch from "../../components/layout/ToggleSwitch.js";
import { categorizeActGroups, transformData } from "./dataTransform";
import { useSocket } from "../../socket/Socket.js";
import useStore from "../../store/store.js";
const RemoteController = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 2rem 0;
  div {
    padding-right: 1rem;
  }
`;
const Title = styled.div`
  padding: 1rem 0;
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
`;

const KEY = {
  1: "WINDOW_TOP",
  2: "WINDOW_SIDE",
  3: "WARMSCREEN",
  4: "BLOCKSCREEN",
  5: "VENT",
  6: "MOVE",
  7: "CO2",
  8: "LED",
  9: "HEATER",
  10: "WATER",
};

const UNITY_WINDOW = {
  "천창 좌": 1,
  "천창 우": 2,
  "측창 좌": 3,
  "측창 우": 4,
};

const UNITY_ONOFF = {
  배기: 5,
  유동: 6,
  가습: 7,
};

const ManualController = () => {
  const { t } = useTranslation("dashboard", { keyPrefix: "manualController" });
  const { farm_id } = useStore();
  const [houseID, setHouseID] = useState(-1);
  const [isLocal, setIsLocal] = useState("N"); // "N" 원격 제어 "Y" 로컬
  const [actGroup, setActGroup] = useState({ windows: [], etc: [] });
  const [transformedActGroup, setTransformedActGroup] = useState({
    windows: [],
    etc: [],
  });
  const { socket, onOffController, windowController } = useSocket();

  const updateData = (target, data) => {
    for (const key in data) {
      if (Array.isArray(data[key])) {
        for (const item of data[key]) {
          if (target.layerID !== undefined && target.layerSubID !== undefined) {
            // windows 체크
            if (
              item.agID === target.agID &&
              item.akID === target.akID &&
              item.layerID === target.layerID &&
              item.layersubID === target.layerSubID
            ) {
              item.oppstate = target.oppstate + "";
              item.openRate = target.openRate;
            }
          } else if (target.onoffID !== undefined) {
            // etc 체크
            if (
              item.agID === target.agID &&
              item.akID === target.akID &&
              item.onoffID === target.onoffID
            ) {
              item.oppstate = target.oppstate + "";
            }
          }
        }
      }
    }
    return data;
  };

  const handleRemoteControl = () => {
    if (isLocal === "N") {
      // 원격 제어  -> 로컬 제어(나 회색, 외부 컴 초록색)
      setIsLocal("Y");
      actonoff.switchControl(socket, houseID, "Y");
    } else {
      setIsLocal("N");
      actonoff.switchControl(socket, houseID, "N");
    }
  };

  const handleSendUnity = (props) => {
    if (window.vuplex) {
      sendMessageToCSharp();
    } else {
      window.addEventListener("vuplexready", sendMessageToCSharp);
    }
    function sendMessageToCSharp() {
      window.vuplex.postMessage(props);
    }
  };

  const handleOnOff = (title, houseID, agID, akID, onoffID, oppstate) => {
    actonoff.operation(socket, houseID, agID, akID, onoffID, oppstate);
  };

  const handleWindow = (
    title,
    houseID,
    agID,
    akID,
    layerID,
    layersubID,
    oppstate,
    openRate
  ) => {
    actwindow.operation(
      socket,
      houseID,
      agID,
      akID,
      layerID,
      layersubID,
      oppstate,
      openRate
    );
  };
  useEffect(() => {
    if (farm_id) {
      getHouse(farm_id)
        .then((res) => {
          setActGroup(categorizeActGroups(res.house[0].actgroup));
          setHouseID(res.house[0].key);
          setIsLocal(res.house[0].islocal);
        })
        .catch((error) =>
          console.error("Failed to fetch act group data:", error)
        );
    }
  }, [farm_id]);

  useEffect(() => {
    const transformedData = transformData(actGroup);
    setTransformedActGroup(transformedData);
  }, [actGroup]);

  useEffect(() => {
    handleSendUnity({ transformedActGroup: transformedActGroup });
  }, [transformedActGroup]);

  useEffect(() => {
    if (onOffController) {
      const updatedData = updateData(onOffController, transformedActGroup);
      setTransformedActGroup(updatedData);
      handleSendUnity({ transformedActGroup: transformedActGroup });
    }
    if (windowController) {
      const updatedData = updateData(windowController, transformedActGroup);
      setTransformedActGroup(updatedData);
      handleSendUnity({ transformedActGroup: transformedActGroup });
    }
  }, [onOffController, windowController]);
  return (
    <>
      <Title>{t("category.windows")}</Title>
      {transformedActGroup.windows && (
        <Wrapper>
          {transformedActGroup.windows.map((act) => (
            <ControllerWindows
              key={act.knd}
              houseID={houseID}
              {...act}
              isLocal={isLocal}
              handleClick={handleWindow}
            />
          ))}
        </Wrapper>
      )}
      <Title>{t("category.etc")}</Title>
      {transformedActGroup.etc && (
        <Wrapper>
          {transformedActGroup.etc.map((act) => (
            <ControllerEtc
              key={act.knd}
              houseID={houseID}
              {...act}
              isLocal={isLocal}
              handleClick={handleOnOff}
            />
          ))}
        </Wrapper>
      )}
      <RemoteController>
        <div>{t("remoteController")}</div>
        <ToggleSwitch state={isLocal} setState={handleRemoteControl} />
      </RemoteController>
    </>
  );
};

export default ManualController;
