import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";
import formatDate from "../utils/formatDate";
import { useTranslation } from "react-i18next";

const Chart = ({ data, config, renderLegend, dataFormat }) => {
  const { t } = useTranslation("accumulatedData");
  // Tick formatter 함수
  const dateFormatter = (tick, format) => {
    // 밀리초 형식인지 확인
    if (!isNaN(tick)) {
      // 밀리초이면
      const date = new Date(tick);
      return formatDate(date, format);
    }
    // 시간 형식이면 그대로 반환
    return tick;
  };
  // Tooltip formatter
  const tooltipFormatter = (value, name, props) => {
    if (Number.isInteger(value)) {
      return [value, name === "rmse" ? "rmse" : t(`data.${name}`)];
    }
    return [
      parseFloat(value).toFixed(2),
      name === "rmse" ? "rmse" : t(`data.${name}`),
    ];
  };

  return (
    <ResponsiveContainer
      width={config.options.width}
      height={config.options.height}
    >
      <LineChart data={data} margin={config.options.margin}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={config.x}
          tickFormatter={(tick) => dateFormatter(tick, dataFormat)}
        />
        <YAxis reversed={config.options.reversed} />
        <Tooltip
          labelFormatter={(tick) => dateFormatter(tick, "YYYY-MM-DD HH:MM")}
          formatter={tooltipFormatter}
        />
        {renderLegend ? (
          <Legend verticalAlign="top" content={renderLegend} />
        ) : null}

        {config.data.length !== 0
          ? config.data.map((s) => (
              <Line
                key={s.dataKey}
                dataKey={s.dataKey}
                stroke={s.stroke}
                name={s.name}
                type={config.options.type}
                dot={config.options.dot}
              />
            ))
          : null}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
