import * as utils from "./utils.js";
import * as actLayer from "./act_layer.js";

export function changeOpenrate(data) {
  var akid_alCnt = utils.pad(data[0].toString(2), 8);
  var alCnt = parseInt(akid_alCnt.substring(4, 8), 2);
  let pos = 1;
  for (let alidx = 0; alidx < alCnt; alidx++) {
    let alDataSize = data[pos++];
    let alData = data.slice(pos, pos + alDataSize);
    var alid_lsCnt = utils.pad(alData[0].toString(2), 8);
    var alid = parseInt(alid_lsCnt.substring(0, 4), 2);
    pos = pos + alDataSize;
    actLayer.changeOpenrate(alData);
  }
}
