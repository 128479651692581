import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import General_X from "../../assets/img/General_X.svg";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: baseline;
`;

const ModalBoxSetup = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: ${(props) => props.width || "45%"};
  overflow: hidden;
  margin: ${(props) => props.margin || "30vh auto"};
  box-sizing: border-box;
  z-index: 1;
  background: #fff;
  /* height: ${(props) => props.height || "11.25rem"}; */
  border-radius: 3px;
`;

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: rgba(0, 0, 0, 0.5);
`;
const StyledCard = styled.div`
  text-align: center;
  color: #4d4d4d;
  /* min-height: 11rem; */
  .card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f9f5;
    padding: 0.5rem 1rem;
  }
  .title {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .card-body {
    padding: 1rem;
    &.model-delete {
      padding: 1rem 9rem;
    }
    &.model-change {
      padding: 1rem 4.5rem;
    }
    line-height: 174%;
  }
  .text {
    font-size: 1.125rem;
  }
`;

const Icon = styled.img`
  width: 0.7rem;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const Button = styled.div`
  width: 6.25rem;
  margin-top: 0.3rem;
  background-color: #5f9137;
  color: #ffffff;
  border-radius: 3px;
`;

const ModalConfirm = ({
  title,
  close,
  model_name,
  content,
  model_id,
  execute,
}) => {
  const { t } = useTranslation("common");
  return (
    <>
      <ModalContainer>
        <ModalBoxSetup>
          <StyledCard>
            <div className="card-head">
              <div className="title">{title}</div>
              <Icon src={General_X} onClick={close} />
            </div>
            <div className={`card-body`}>
              <div className="text">{content}</div>
              <ButtonWrapper>
                <Button onClick={() => execute()}>{t("yes")}</Button>
              </ButtonWrapper>
            </div>
          </StyledCard>
        </ModalBoxSetup>
        <ModalBackground onClick={close} />
      </ModalContainer>
    </>
  );
};

export default ModalConfirm;
