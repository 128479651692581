import React, { useEffect } from "react";
import useStore from "../../store/store";
import { useNavigate } from "react-router-dom";
import { kakaoLogin } from "../../api/AuthApi";
// import { setApiHeaders } from "../../api/Axios";

function Auth() {
  const navigate = useNavigate();
  const { setIsLoggedIn } = useStore();
  const loginCode = new URL(window.location.href).searchParams.get("code");

  useEffect(() => {
    async function fetchData() {
      console.log(loginCode);
      await kakaoLogin(loginCode)
        .then((res) => {
          if (res) {
            setIsLoggedIn(true);
            localStorage.setItem(
              "access_token",
              JSON.stringify(response.data.user.access_token)
            );
            // setApiHeaders();
            navigate("/dashboard");
          } else {
            console.log("no res?!?!?");
            navigate("/");
          }
        })
        .catch((err) => {
          console.log("err", err);
          navigate("/");
        });
    }
    fetchData();
  }, []);

  // const getToken = async () => {
  //   const token = new URL(window.location.href).searchParams.get("code");
  //   console.log("token:", token);

  //   // 백엔드 서버로 Kakao 코드를 전송하여 토큰을 받아옴
  //   const res = await axios.post(
  //     "http://tapi.goatfarm.ai/api/v1/users/social/kakao",
  //     { code: token },
  //     {
  //       headers: {
  //         "Content-type": "application/json",
  //       },
  //     }
  //   );
  //   return res;
  // };

  // useEffect(() => {
  //   getToken()
  //     .then((res) => {
  //       if (res) {
  //         console.log("res:", res);
  //         localStorage.setItem("token", JSON.stringify(res.data.access_token));
  //         navigate("/"); // 메인 페이지로 리디렉션
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  return <></>;
}

export default Auth;
