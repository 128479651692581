import { instance } from "./Axios";

export const getJournalList = async (api_key, farm_id, pl_code, page) => {
  const response = await instance.post("dbserver/goatfarm/journals", {
    api_key: api_key,
    farm_id: farm_id,
    pl_code: pl_code,
    page: page,
  });
  return response.data;
};

export const getJournalDetail = async (
  api_key,
  farm_id,
  pl_code,
  journal_seq
) => {
  const response = await instance.post(
    `dbserver/goatfarm/journals/${journal_seq}`,
    {
      api_key: api_key,
      farm_id: farm_id,
      pl_code: pl_code,
    }
  );
  return response.data;
};

export const createJournal = async (
  api_key,
  farm_id,
  pl_id_cde,
  pl_code,
  data,
  date,
  transplanting
) => {
  const response = await instance.post(`dbserver/goatfarm/createjournal`, {
    api_key: api_key,
    farm_id: farm_id,
    pl_id_cde: pl_id_cde,
    pl_code: pl_code,
    data: data,
    date: date,
    transplanting: transplanting,
  });
  return response.data;
};

export const editJournal = async (
  api_key,
  farm_id,
  pl_id_cde,
  pl_code,
  journal_seq,
  data,
  date,
  transplanting
) => {
  const response = await instance.put(
    `dbserver/goatfarm/journals/${journal_seq}`,
    {
      api_key: api_key,
      farm_id: farm_id,
      pl_id_cde: pl_id_cde,
      pl_code: pl_code,
      data: data,
      date: date,
      transplanting: transplanting,
    }
  );
  return response.data;
};

export const deleteJournal = async (api_key, farm_id, pl_code, journal_seq) => {
  const response = await instance.delete(
    `dbserver/goatfarm/journals/${journal_seq}`,
    {
      data: {
        // axios delete는 일반적으로 request body 지원 안 함. 대신 두 번째 인수로 config 객체를 받으며 data 속성 안에 추가 가능
        api_key: api_key,
        farm_id: farm_id,
        pl_code: pl_code,
      },
    }
  );
  return response.data;
};
