const farmConfig = {
  KRAI000001: {
    id: "goatdemo",
  },
  KRAI100001: {
    id: "asan",
  },
  KRAI200001: {
    id: "goat_sup",
  },
};

export default farmConfig;
