import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../components/ProgressBar";

import Temperature from "../../assets/img/Sensor_Temperature.svg";
import Humidity from "../../assets/img/Sensor_Humidity.svg";
import SolarRadiation from "../../assets/img/Sensor_SolarRadiation.svg";
import PH from "../../assets/img/Sensor_PH.svg";
import EC from "../../assets/img/Sensor_EC.svg";
import Menu_Radar from "../../assets/img/Menu_Radar.svg";

const ListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.3rem;
  font-size: 0.85rem;
  .title__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const IconWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 100%;
`;

const Icon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

const IconDICT = {
  온도: Temperature,
  습도: Humidity,
  일사: SolarRadiation,
  EC: EC,
  pH: PH,
  이슬점: Temperature,
  HD: Humidity,
};

const SensorItem = ({ title, value, maximum, minimum, unit }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "sensor" });

  const IconComponent = IconDICT[title];
  return (
    <>
      {title && (
        <ListItemWrapper>
          <IconWrapper>
            {IconComponent ? (
              <Icon src={IconComponent} />
            ) : (
              <Icon src={Menu_Radar} />
            )}
          </IconWrapper>
          <Wrapper>
            <div className="title__wrapper">
              <div>{t(`data.${title}`)}</div>
              <div>
                {value} {unit}
              </div>
            </div>
            <ProgressBar
              value={value}
              minimum={minimum}
              maximum={maximum}
              status={
                minimum > value || maximum < value ? "#E8C600" : "#395EF6"
              }
            />
          </Wrapper>
        </ListItemWrapper>
      )}
    </>
  );
};

export default SensorItem;
