import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSocket } from "../../socket/Socket";
import { aiChoose } from "../../socket/ai_control";
import useStore from "../../store/store";
import ModalConfirm from "./ModalConfirm";
import Dropdown_Down from "../../assets/img/Dropdown_Down.svg";
import Dropdown_Up from "../../assets/img/Dropdown_Up.svg";

const Container = styled.div`
  display: flex;
`;

const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  svg {
    width: 16px;
  }
`;

const ListWrapper = styled.div`
  z-index: 1;
  position: absolute;
  width: 14%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  color: #5f9137;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
  border-radius: 0 0 4px 4px;
  margin-top: 2rem;
  padding: 0.8rem;
  max-height: 15rem;
`;

const List = styled.div`
  max-height: 10rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 7px solid #ffffff;
    background: #cfdec3;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    border: 7px solid #ffffff;
    background: #cfdec34d;
  }
`;

const ListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.active,
  &:hover {
    background-color: #f1f5ed;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0.7rem;
  border-radius: 3px;
  font-size: 0.75rem;
  color: #4d4d4d;
`;

const Icon = styled.img`
  width: 1rem;
  height: 1rem;
`;

const MODE = { M: "시간별 조건 제어", A: "수동 제어" }; // 변경 예정 모달이라 진짜 코드랑 반대

const ManualAutoDropdown = () => {
  const { t } = useTranslation("dashboard");
  const { houseCode, actGrp } = useStore();
  const { socket, isAi } = useSocket();
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef();
  const itemRef = useRef();

  // 컨트롤 방식 선택하는 모달
  const [chosenController, setChosenController] = useState("");
  // 변경 모달 제어
  const [changeModal, setChangeModal] = useState(false);

  // 바깥 영역 클릭시
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdown(false);
    }
  };

  // 모달 열기 제어
  const handleOpenModal = (mode) => {
    setChangeModal(true);
    setChosenController(mode);
  };

  const handleCloseModal = () => {
    setChangeModal(false);
    setChosenController("");
  };

  // 컨펌후 실제 변경
  const handleChangeModel = (mode) => {
    aiChoose(socket, houseCode, actGrp, mode);
    setChangeModal(false);
    setDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <Container ref={dropdownRef}>
      <DropdownWrapper>
        <div>{isAi === 3 ? "시간별 조건 제어" : "수동 제어"}</div>
        {!dropdown ? (
          <Icon src={Dropdown_Down} onClick={() => setDropdown(!dropdown)} />
        ) : (
          <Icon src={Dropdown_Up} onClick={() => setDropdown(!dropdown)} />
        )}
      </DropdownWrapper>
      {dropdown && (
        <ListWrapper>
          <List ref={itemRef}>
            <ListItemWrapper>
              <ListItem
                onClick={isAi === 3 ? () => handleOpenModal("M") : null}
              >
                수동 제어
              </ListItem>
            </ListItemWrapper>
            <ListItemWrapper>
              <ListItem
                onClick={isAi === 4 ? () => handleOpenModal("A") : null}
              >
                시간별 조건 제어
              </ListItem>
            </ListItemWrapper>
          </List>
        </ListWrapper>
      )}

      {changeModal ? (
        <ModalConfirm
          title={"컨트롤 방식 변경"}
          close={handleCloseModal} // Modal 여닫기
          model_name={chosenController}
          content={
            isAi === 3
              ? `수동 제어 모드로 변경하시겠습니까?`
              : `시간별 조건 제어 모드로 변경하시겠습니까?`
          }
          execute={() => handleChangeModel(chosenController)} // Model 둘이 다름!! 주의
        />
      ) : null}
    </Container>
  );
};

export default ManualAutoDropdown;
