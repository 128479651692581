import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import Sidebar_Window from "../../assets/img/Sidebar_Window.svg";
import Sidebar_Chart from "../../assets/img/Sidebar_Chart.svg";
import Sidebar_CCTV from "../../assets/img/Sidebar_CCTV.svg";
import Sidebar_Calendar from "../../assets/img/Sidebar_Calendar.svg";
import General_Settings from "../../assets/img/General_Settings.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100vh;
  width: 4.375rem;
  transition: 0.3s;
  background-color: #5f91374d;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledNav = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c6c6c6;
  padding: 1.5rem;
  &.active {
    background-color: #ffffff;
  }
`;

const Icon = styled.img`
  width: 4.5rem;
`;

const menu = [
  { id: 1, title: "대시보드", route: "/dashboard", icon: Sidebar_Window },
  { id: 2, title: "CCTV", route: "/cctv", icon: Sidebar_CCTV },
  { id: 3, title: "누적 데이터", route: "/data", icon: Sidebar_Chart },
  { id: 4, title: "생육 조사", route: "/monitoring", icon: Sidebar_Calendar },
];

const Sidebar = () => {
  return (
    <Container>
      <Wrapper>
        {menu.map((item) => (
          <StyledNav key={item.id} to={item.route}>
            <Icon src={item.icon} />
          </StyledNav>
        ))}
      </Wrapper>
      <StyledNav to="/settings">
        <Icon src={General_Settings} />
      </StyledNav>
    </Container>
  );
};

export default Sidebar;
