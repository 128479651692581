import React from "react";
import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: baseline;
`;

const ModalBoxSetup = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: ${(props) => props.width || "45%"};
  overflow: hidden;
  margin: ${(props) => props.margin || "30vh auto"};
  box-sizing: border-box;
  z-index: 1;
  background: #fff;
  /* height: ${(props) => props.height || ""}; */
  border-radius: 3px;
`;

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const Modal = ({ show, reject, content, style }) => {
  return (
    <>
      {show ? (
        <ModalContainer>
          <ModalBoxSetup
            width={style.width}
            height={style.height}
            margin={style.margin}
          >
            {content}
          </ModalBoxSetup>
          <ModalBackground onClick={reject} />
        </ModalContainer>
      ) : null}
    </>
  );
};

export default Modal;
