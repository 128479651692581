import React, { useEffect, useState } from "react";
import {
  C_WEB_CLIENT_CONNECTED,
  C_WEB_CLIENT_CONNECTED_COMPLETE,
} from "../../socket/Consts";

function RmseSocket() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // const ws = new WebSocket("ws://34.22.109.227:8001/api/v1/ws/rmse/"); // test server
    const ws = new WebSocket(process.env.REACT_APP_RMSE_SOCKET_URL);
    ws.binaryType = "arraybuffer"; // 웹소켓에서 바이너리 데이터를 받도록 설정

    ws.onopen = () => {
      const buffer = new Uint8Array([C_WEB_CLIENT_CONNECTED]);
      ws.send(buffer.buffer); // ArrayBuffer를 보내야 하므로 buffer.buffer 사용
      // console.log("C_WEB_CLIENT_CONNECTED : [" + buffer[0] + "]");
    };

    ws.onmessage = (event) => {
      // console.log("Received data: ", event.data);
      const buffer = new Uint8Array(event.data);
      processData(buffer);
    };

    return () => ws.close(); // 컴포넌트 언마운트 시 웹소켓 연결 닫기
  }, []);

  const processData = (buffer) => {
    const decoder = new TextDecoder("utf-8");
    const decodedData = decoder.decode(buffer);
    try {
      const jsonData = JSON.parse(decodedData);
      setData((data) => [...data, jsonData]); // 데이터 누적
    } catch (error) {
      console.error("Error parsing JSON data: ", error);
    }
  };
  return data;
}

export default RmseSocket;
