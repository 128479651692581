import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSocket } from "../../socket/Socket";
import { aiChoose } from "../../socket/ai_control";
import AiController from "./AiController";
import ManualController from "./ManualController";
import AutoController from "./AutoController";
import AiDropdown from "./AiDropdown";
import ManualAutoDropdown from "./ManualAutoDropdown";
import ModalConfirm from "./ModalConfirm";
import { getHouse } from "../../api/SocketApi";
import useStore from "../../store/store";
import Spinner from "../../components/Spinner";

const Container = styled.div`
  width: 46%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #ffffff;
  grid-area: env;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  align-items: center;
`;

const Button = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  width: 85%;
  height: 1.4rem;
  border-radius: 3rem;
  font-size: 1.125rem;
  &.active {
    background-color: #5f9137;
    color: #ffffff;
  }
  &.inactive {
    background-color: #ffffff;
    border: #5f9137 solid 1px;
    color: #4d4d4d;
  }
`;
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30vh;
`;

const MODE = { F: "수동 제어", M: "자동 제어", A: "수동 제어" }; // 변경 예정 모달이라 진짜 코드랑 반대

const EnvController = () => {
  const { t } = useTranslation("dashboard");
  const { farm_id, houseCode, setHouseCode, actGrp, setActGrp } = useStore();
  const { socket, isAi } = useSocket();

  const [controller, setController] = useState(""); // true: 자동제어(Ai)
  const [modal, setModal] = useState(false);

  // 로직 변경하기
  const handleChange = () => {
    if (controller === "F" || controller === "S" || controller === "A") {
      // 클릭시 반대 되게 해야됨
      // 수동제어로 변경
      setController("M");
      aiChoose(socket, houseCode, actGrp, "M");
    } else if (controller === "M") {
      setController("F");
      aiChoose(socket, houseCode, actGrp, "F");
    }
    setModal(!modal);
  };

  // 변경 모달 제어
  const handleClick = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (farm_id) {
      getHouse(farm_id)
        .then((res) => {
          setHouseCode(res.key);
          setActGrp(res.house[0].key);
          setController(res.house[0].actgroup[0].ia);
        })
        .catch((error) => console.error("Error getting  list:", error));
    }
  }, [farm_id]);

  useEffect(() => {
    if (houseCode !== -1 && actGrp !== -1) {
      aiChoose(socket, houseCode, actGrp, controller);
    }
  }, [houseCode, controller, actGrp, socket]);

  /*
  if (opp == "F") // AI
  buffer[4] = 1;
  else if (opp == "S") //semi ai
  buffer[4] = 2;
  else if (opp == "A") //auto
  buffer[4] = 3
  else if (opp == "M") //manual
  buffer[4] = 4;
  */
  return (
    <Container>
      <Wrapper>
        <Button
          className={isAi === 1 ? "active" : "inactive"}
          onClick={isAi === 1 ? null : handleClick}
        >
          <AiDropdown />
        </Button>
        <Button
          className={isAi === 3 || isAi === 4 ? "active" : "inactive"}
          onClick={isAi === 3 || isAi === 4 ? null : handleClick}
        >
          <ManualAutoDropdown />
          {/* <Text>{t("manualController.title")}</Text> */}
        </Button>
      </Wrapper>

      {isAi === 1 ? (
        <AiController />
      ) : isAi === 3 ? (
        <AutoController />
      ) : isAi === 4 ? (
        <ManualController />
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      {modal && (
        <ModalConfirm
          title={t("envController.modal.title")}
          close={handleClick}
          content={t(`envController.modal.${MODE[controller]}`)}
          execute={handleChange}
        />
      )}
    </Container>
  );
};

export default EnvController;
