import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useStore from "../../../store/store";
import { getAlarms } from "../../../api/NotificationApi";
import useInterval from "../../../hooks/useInterval";
import LanguageDropdown from "./LanguageDropdown";
import GF_Wordmark from "../../../assets/img/GF_Wordmark.svg";
import Navbar_Alarm from "../../../assets/img/Navbar_Alarm.svg";
import Navbar_House from "../../../assets/img/Navbar_House.svg";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 2;
  position: fixed;
  top: 0;
  height: 3.875rem;
  width: -webkit-fill-available;
  background-color: white;
  padding-left: 5.2rem;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Wordmark = styled.img`
  display: flex;
  height: 1.9rem;
  padding: 1rem 0;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.68rem;
  color: #5f9137;
  border: 1px solid #5f9137;
  font-size: 1.5rem;
  padding: 0 1rem;
  margin-left: 1.2rem;
`;
const Check = styled.img`
  width: 1.5rem;
  margin-left: 0.5rem;
`;
const AlarmWrapper = styled.div`
  display: flex;
`;
const Alarm = styled.img`
  display: flex;
  justify-content: center;
  width: 1.9rem;
  align-items: center;
  padding: 1rem 1.5rem;
  color: #5f9137;
`;
const Badge = styled.div`
  z-index: 1;
  position: absolute;
  right: 16px;
  top: 8px;
  color: white;
  font-size: 1rem;
  background-color: #cd3534;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.05rem;
`;

const Navbar = () => {
  const { t } = useTranslation("dashboard");
  const { api_key, farm_id } = useStore();
  const [date, setDate] = useState(new Date().getTime());
  const [alarms, setAlarms] = useState([]);
  const [unreadMsg, setUnreadMsg] = useState(0);
  const pathname = useLocation().pathname;

  useEffect(() => {
    if (farm_id) {
      getAlarms(api_key, farm_id, date)
        .then((res) => {
          setAlarms(res);
          setUnreadMsg(res.filter((item) => item.readStatus === false).length);
        })
        .catch((error) => console.error("Error inserting alarm data:", error));
    }
  }, [farm_id]);

  useInterval(() => {
    getAlarms(api_key, farm_id, date)
      .then((res) => {
        setAlarms(res);
        setUnreadMsg(res.filter((item) => item.readStatus === false).length);
      })
      .catch((error) => console.error("Error inserting alarm data:", error));
  }, 60000); // 1분에 한 번씩 알림 요청

  return (
    <>
      <Container>
        <IconWrapper>
          <Wordmark src={GF_Wordmark} alt="goat-farm" />
        </IconWrapper>
        <Wrapper>
          {pathname === "/settings" ? (
            <LanguageDropdown />
          ) : (
            <>
              <Button>
                {t(`farm.${farm_id}`)}
                <Check src={Navbar_House} alt="" />
              </Button>
              <Link to="/notification">
                <AlarmWrapper>
                  <Alarm src={Navbar_Alarm} alt="" />
                  {unreadMsg ? (
                    <Badge>{unreadMsg < 100 ? unreadMsg : 99}</Badge>
                  ) : (
                    <></>
                  )}
                </AlarmWrapper>
              </Link>
            </>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default Navbar;
