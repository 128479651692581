import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import General_X from "../assets/img/General_X.svg";

const StyledCard = styled.div`
  text-align: center;
  color: #4d4d4d;
  min-height: 11rem;
  .card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f9f5;
    padding: 0.5rem 1rem;
  }
  .title {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .card-body {
    padding: 1rem;
    &.model-delete {
      padding: 1rem 9rem;
    }
    &.model-change {
      padding: 1rem 4.5rem;
    }
    line-height: 174%;
  }
  .text {
    font-size: 1.125rem;
  }
`;

const Icon = styled.img`
  width: 0.7rem;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.div`
  width: 6.25rem;
  margin-top: 0.3rem;
  background-color: #5f9137;
  color: #ffffff;
  border-radius: 3px;
`;

const Card = ({ title, close, text, note, props, className }) => {
  return (
    <StyledCard>
      <div className="card-head">
        <div className="title">{title}</div>
        <Icon src={General_X} onClick={close} />
      </div>
      <div className={`card-body ${className}`}>
        <div className="text">{text}</div>
        <div>{note}</div>
        {title === "인공지능 모델 변경" || title === "학습 모델 삭제하기" ? (
          <ButtonWrapper>
            <Button onClick={props}>확인</Button>
          </ButtonWrapper>
        ) : null}
      </div>
    </StyledCard>
  );
};

Card.propTypes = {
  close: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default Card;
