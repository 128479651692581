import * as utils from "./utils.js";
import * as sensornode from "./sensornode.js";
import * as actGrp from "./act_grp.js";

export function refreshData(rev_data, item_house) {
  let snDataSize = 0;
  let pos = 0;
  let snCnt = rev_data[pos++];
  //console.log(snCnt);
  //======================================
  //온실내에 있는 센서노드 갯수
  item_house.sensorNodeCount = snCnt;
  //복수개의 센서노드
  item_house.sensorNodes = [];
  //======================================

  for (let snidx = 0; snidx < snCnt; snidx++) {
    let base2 = utils.pad(rev_data[pos++].toString(2), 8);
    let substr = base2.substring(0, 4);
    let nid = parseInt(substr, 2);
    substr = base2.substring(4, 8);
    let sCnt = parseInt(substr, 2);
    snDataSize += sCnt * 3;
    let battery = rev_data[pos++];
    let snData = rev_data.slice(pos, pos + snDataSize);

    //======================================
    //센서노드 생성
    let item_sensorNode = {};
    //센서노드 ID
    item_sensorNode.sensorNodeID = nid;
    //센서노드의 베터리 잔량
    item_sensorNode.battery = battery;
    item_house.sensorNodes.push(item_sensorNode);
    //======================================
    //======================================
    //item_sensorNode -> last parameter
    //======================================
    sensornode.refreshData(sCnt, snData, item_sensorNode);
    pos += sCnt * 3;
  }
}

export function changeOpenrate(data) {
  let hc_agCnt = utils.pad(data[0].toString(2), 8);
  let agCnt = parseInt(hc_agCnt.substring(4, 8), 2);
  let pos = 1;
  for (let agidx = 0; agidx < agCnt; agidx++) {
    let agDataSize = data[pos++];
    let agData = data.slice(pos, pos + agDataSize);
    let agid_akCnt = utils.pad(agData[0].toString(2), 8);
    let agid = parseInt(agid_akCnt.substring(0, 4), 2);
    pos = pos + agDataSize;
    actGrp.changeOpenrate(agData);
  }
}
