import { instance } from "./Axios";

export const getGreenhouseData = async (
  id,
  farm_id,
  house_cde,
  from_date,
  to_date
) => {
  const response = await instance.get("dbserver/goatfarm/ict/datalist", {
    params: {
      id: id,
      farm_cde: farm_id,
      house_cde: house_cde,
      from_date: from_date,
      to_date: to_date,
    },
  });

  return response.data.data;
};

export const getGreenhouseDataRealTime = async (
  id,
  farm_id,
  house_cde,
  from_date,
  to_date,
  nid
) => {
  const response = await instance.get("dbserver/goatfarm/ict/datalist", {
    params: {
      id: id,
      farm_cde: farm_id,
      house_cde: house_cde,
      from_date: from_date,
      to_date: to_date,
      nid: nid,
    },
  });
  return response.data.data;
};

// 기존 정읍
// export const getGreenhouseData = async (
//   farm_id,
//   house_cde,
//   from_date,
//   to_date,
//   id
// ) => {
//   const response = await tnsInstance.get("ict/datalist", {
//     params: {
//       id: id,
//       farm_cde: farm_id,
//       house_cde: house_cde,
//       from_date: from_date,
//       to_date: to_date,
//     },
//   });

//   return response.data.data;
// };

// http://tns.tenefarm.com/ict/datalist?id=ai_measuredata_day&farm_cde=KRAI000001&house_cde=1&from_date=2024-06-25&to_date=2024-07-01
// http://tns.tenefarm.com/ict/datalist?id=ai_measuredata_hour&farm_cde=KRAI000001&house_cde=1&from_date=2024-06-25&to_date=2024-07-03
// http://tns.tenefarm.com/ict/datalist?id=ai_tranddata&farm_cde=KRAI000001&house_cde=1&nid=1
