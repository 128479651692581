import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import AssembleSensorData from "./AssembleSensorData";
import EnvController from "./EnvController";
import ExtWeather from "./ExtWeather";
import Sensor from "./Sensor";
import { useSocket } from "../../socket/Socket";
import useStore from "../../store/store";

const Container = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0.8rem 1rem 0 1rem;
`;
const Wrapper = styled.div`
  width: 49%;
  gap: 1rem;
`;

const Dashboard = () => {
  // 웹소켓 데이터
  const { socket_url } = useStore();

  if (socket_url) {
    let { socketData } = useSocket();

    // 센서 데이터 담을 구조 만들어줌(value 없이, Sensor.js)
    const sensorStruc = AssembleSensorData();

    // 페이지 리로드
    useEffect(() => {
      const interval = setInterval(() => {
        window.location.reload();
      }, 7200000); // 2 시간

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
      socketData && (
        <>
          <Container>
            <EnvController />
            <Wrapper>
              <ExtWeather
                weatherstation={socketData?.weatherstation}
                sunrise={socketData?.sunrise}
                sunset={socketData?.sunset}
              />
              <Sensor
                structure={sensorStruc}
                houseSensors={socketData?.houseSensors}
              />
            </Wrapper>
          </Container>
        </>
      )
    );
  }
};

export default Dashboard;
