import { instance } from "./Axios";

export const getRmse = async (
  api_key,
  farm_id,
  pl_code,
  pl_mdoel,
  date,
  datatype
) => {
  const response = await instance.post("dbserver/goatfarm/getRmse", {
    api_key: api_key,
    farm_id: farm_id,
    pl_code: pl_code,
    pl_mdoel: pl_mdoel,
    date: date,
    datatype: datatype,
  });
  return response.data;
};

// 0 : 5분
// 1.: 1시간
// 2 : 1일
// 3 : 1주
