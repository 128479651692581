import formatDate from "../../utils/formatDate";

export const LOMAINE_ASAN = "08001";
export const STRAWBERRY = 2;
export const TOMATO = "02001";
export const CURLY_CHILI = 4;
//생육데이터 수집 알림
//엽채류는 3일에 한번씩
//딸기, 토마토는 7일에 한번씩 총 10개월 동안

export function getGDataInputAlarm(seededDate, plantSort) {
  const sDate = new Date(seededDate);
  let gTerm = [];

  switch (plantSort) {
    case LOMAINE_ASAN:
      gTerm[0] = sDate.getTime() + 2.592e8;
      for (let i = 1; i < 11; i++) {
        gTerm[i] = gTerm[i - 1] + 2.592e8;
      }
      break;
    case STRAWBERRY || TOMATO:
      gTerm[0] = sDate.getTime() + 6.048e8;
      for (let i = 1; i < 41; i++) {
        gTerm[i] = gTerm[i - 1] + 6.048e8;
      }
      break;
    default:
      gTerm[0] = 0;
  }

  return gTerm;
}

//수확 시기 알림
export function getCroppingDateAlarm(seededDate, plantSort) {
  const sDate = new Date(seededDate);
  let gTerm = [];

  switch (plantSort) {
    //아산 로메인은 정식 후 27일, 30일 두번 알림
    case LOMAINE_ASAN:
      gTerm[0] = sDate.getTime() + 2.333e9;
      gTerm[1] = sDate.getTime() + 2.592e9;
      break;
    //토마토는 정식 후 90일, 97일 두번
    case TOMATO:
      gTerm[0] = sDate.getTime() + 7.776e9;
      gTerm[1] = sDate.getTime() + 8.381e9;
      break;
    case STRAWBERRY:
      //딸기는 정식 후 60일, 67일 두번
      gTerm[0] = sDate.getTime() + 5.184e9;
      gTerm[1] = sDate.getTime() + 5.789e9;
      break;
    default:
      gTerm[0] = 0;
  }
  return gTerm;
}

export function getAlarmDates(seededDate, plantSort) {
  const gDataInputAlarms = getGDataInputAlarm(seededDate, plantSort);
  const croppingDateAlarms = getCroppingDateAlarm(seededDate, plantSort);

  const alarmDates = [];

  // gDataInputAlarms 날짜 형식 변환 및 alarmDates 배열 생성
  gDataInputAlarms.forEach((date) => {
    alarmDates.push({
      date: formatDate(date, "YYYY-MM-DD HH:MM:SS"),
      alCode: "A0001",
      readStatus: "N",
    });
  });

  // croppingDateAlarms 날짜 형식 변환 및 alarmDates 배열에 추가
  croppingDateAlarms.forEach((date) => {
    alarmDates.push({
      date: formatDate(date, "YYYY-MM-DD HH:MM:SS"),
      alCode: "A0002",
      readStatus: "N",
    });
  });

  return alarmDates;
}

// // 사용 예시
// const alarms = getAlarmDates("2024-11-23 21:42:25", LOMAINE_ASAN);
// console.log(alarms);
